import { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import { ModalContext } from "../context/ModalContext";
import { useHttp } from "../hooks/http.hook";
import { InputLabel } from "./UI/InputLabel";
import { useTranslation } from "react-i18next";

export const LoginForm = ({ method, setMethod }) => {
  const { request, loading, error, clearError } = useHttp();
  const auth = useContext(AuthContext);
  const modal = useContext(ModalContext);
  const history = useHistory();
  const { t } = useTranslation();
  const ref = useRef(window.sessionStorage.getItem("ref"));

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, dirtyFields },
  } = useForm();

  useEffect(() => {
    if (error) {
      clearError();
      setError("password", { type: "manual", message: t(error) });
    }
  }, [error, clearError]);

  const onSubmit = async (data) => {
    try {
      const response = await request(`/user/login`, "POST", null, data);
      auth.login(response.token);
      modal.destroy();
    } catch (e) { }
  };

  const fields = [
    {
      name: "email",
      icon: "email-circle",
      input: {
        type: "text",
        placeholder: t("placeholder.your_email"),
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
        pattern: {
          value: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
          message: t("errors.input.email"),
        },
      },
    },
    {
      name: "password",
      icon: "key",
      input: {
        type: "password",
        placeholder: t("placeholder.password"),
        disabled: loading,
      },
      validate: { required: t("errors.input.required") },
    },
  ];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center"
    >
      {method === "telegram" ? (
        <a
          href={ref.current ? `${process.env.REACT_APP_TG_BOT_URL}?start=ref_${ref.current}` : process.env.REACT_APP_TG_BOT_URL}
          className="btn btn-accent telegram-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="mr-2"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
              <path
                fill="currentColor"
                d="M19.777 4.43a1.5 1.5 0 0 1 2.062 1.626l-2.268 13.757c-.22 1.327-1.676 2.088-2.893 1.427c-1.018-.553-2.53-1.405-3.89-2.294c-.68-.445-2.763-1.87-2.507-2.884c.22-.867 3.72-4.125 5.72-6.062c.785-.761.427-1.2-.5-.5c-2.303 1.738-5.998 4.381-7.22 5.125c-1.078.656-1.64.768-2.312.656c-1.226-.204-2.363-.52-3.291-.905c-1.254-.52-1.193-2.244-.001-2.746l17.1-7.2Z"
              />
            </g>
          </svg>
          {t("continue_with_telegram")}
        </a>
      ) : (
        <>
          {fields.map((field) => (
            <InputLabel
              icon={field.icon}
              key={field.name}
              setting={{
                ...field.input,
                ...register(field.name, field.validate),
                ...field.callable,
              }}
              error={dirtyFields[field.name] && errors[field.name] && errors[field.name].message}
            />
          ))}
          <a
            href="!#"
            className="link"
            style={{
              display: "block",
              width: "max-content",
              marginLeft: "auto",
            }}
            onClick={(e) => {
              e.preventDefault();
              modal.destroy();
              history.push("/contact");
            }}
          >
            {t("forget_password")}
          </a>
        </>
      )}
    </form>
  );
};
