import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import { Loader } from "../../components/UI/Loader/Loader";
import { useHttp } from "../../hooks/http.hook";
import { useIsMobile } from "../../hooks/isMobile.hook";
import "./style.scss";

export const ReportsPage = () => {
  const { t, i18n } = useTranslation();
  const { request, loading } = useHttp();
  const [reports, setReports] = useState([]);
  const isMobile = useIsMobile();
  const [ready, setReady] = useState(false);
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(isMobile ? 3 : 6);
  const [end, setEnd] = useState(false);

  const getReports = useCallback(async () => {
    try {
      const response = await request(`/reports?limit=${limit}&page=${page}`);
      setEnd(response.length < limit);
      setReports((prev) => [...prev, ...response]);
      setReady(true);
    } catch (e) {}
  }, [request, page]);

  const getDay = (date) => new Date(date).getDate();
  const getMonth = (date) => new Date(date).getMonth();
  const getYear = (date) => new Date(date).getFullYear();

  useEffect(() => {
    getReports();
  }, [getReports]);
  return (
    <Page>
      <main className="main">
        <div className="page-title report__title">
          {t("page.reports.title")}
        </div>
        <div className="report__list">
          {!ready ? (
            <Loader />
          ) : (
            reports.map((report) => {
              return (
                <>
                  {report.pause_start ? (
                    <div className="report__item pause">
                      <img
                        src="/assets/image/calendar.png"
                        className="mx-auto"
                      />
                      <span>
                        {t("page.reports.pause_until", {
                          date: new Intl.DateTimeFormat(i18n.language, {
                            month: "long",
                            day: "numeric",
                          }).format(new Date(report.pause_end)),
                        })}
                      </span>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        history.push("/reports/" + report.id);
                      }}
                      className="report__item"
                    >
                      <div className="report__name">{report.name}</div>
                      <div className="report__date">
                        {t("months", { returnObjects: true })[
                          getMonth(report.date)
                        ] +
                          " " +
                          getDay(report.date) +
                          ", " +
                          getYear(report.date)}
                      </div>
                      <div className="report__images">
                        <div className="report__image">
                          <img
                            src={
                              process.env.REACT_APP_API_HOST +
                              report.command_1_logo
                            }
                            alt=""
                          />
                        </div>
                        <div className="report__vs">vs</div>
                        <div className="report__image">
                          <img
                            src={
                              process.env.REACT_APP_API_HOST +
                              report.command_2_logo
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="report__commands">{report.commands}</div>
                    </div>
                  )}
                </>
              );
            })
          )}
        </div>
        {ready && !reports.length ? (
          <p>{t("page.reports.no_reports")}</p>
        ) : (
          <></>
        )}
      </main>
      {!end && (
        <button
          className="btn btn-secondary"
          style={{ margin: "20px auto" }}
          disabled={loading}
          onClick={() => {
            setPage((prev) => prev + 1);
          }}
        >
          {t("more_articles")}
        </button>
      )}

      <div className="stat-page__promo" style={{ marginTop: 20 }}>
        <div className="backlight backlight-turquoise promo-contact__decoration-one"></div>
        <div className="backlight backlight-turquoise promo-contact__decoration-two"></div>
        <div className="backlight backlight-turquoise promo-contact__decoration-three"></div>
        <div className="backlight backlight-turquoise promo-contact__decoration-four"></div>
        <img src="/assets/image/phone-in-hand-2.png" alt="Phone in hand" />
        <p>{t("page.reports.description")}</p>
      </div>
    </Page>
  );
};
