import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Fancybox } from "../../components/Fancybox";
import { Page } from "../../components/Layout/Page";
import { Loader } from "../../components/UI/Loader/Loader";
import { LoaderMini } from "../../components/UI/Loader/LoaderMini";
import { AuthContext } from "../../context/AuthContext";
import { useHttp } from "../../hooks/http.hook";

export const ReportPage = () => {
  const { t } = useTranslation();
  const { request, loading } = useHttp();
  const { id } = useParams();
  const { isAuth, role, token } = useContext(AuthContext);
  const [report, setReport] = useState({});
  const history = useHistory();

  const getReport = useCallback(async () => {
    try {
      const response = await request(`/reports/${id}`);
      setReport(response);
    } catch (e) {
      history.push("/reports");
    }
  }, [request, id, history]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  const deleteReport = async () => {
    try {
      await request(`/reports/${id}`, "DELETE", token);
      history.push("/reports");
    } catch (e) {}
  };

  const getDay = (date) => new Date(date).getDate();
  const getMonth = (date) => new Date(date).getMonth();

  return (
    <Page>
      <main className="main">
        <div className="page-title">
          {t("page.reports.title")}{" "}
          {loading ? (
            <LoaderMini />
          ) : (
            <>
              {"(" +
                getDay(report.date) +
                " " +
                t("months", { returnObjects: true })[getMonth(report.date)] +
                ")"}
              {isAuth && role === process.env.REACT_APP_ROLE_ADMIN ? (
                <span
                  onClick={deleteReport}
                  className="btn btn-remove btn-inline"
                >
                  Удалить
                </span>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <video className="report__video" controls controlsList="nodownload">
            <source
              src={process.env.REACT_APP_API_HOST + report.video}
              type="video/mp4"
            />
            Your browser doesn't support HTML5 video tag.
          </video>
        )}
      </main>
      <div className="main">
        <div className="page-title">{t("page.reports.your_bets")}</div>
        {loading ? (
          <Loader />
        ) : (
          <Fancybox>
            {Array.isArray(report.reports_images) && (
              <div className="report__screen screen-report">
                {report.reports_images.map((item) => {
                  return (
                    <a
                      data-fancybox="gallery"
                      href={process.env.REACT_APP_API_HOST + item.image}
                      className="screen-report__item"
                      key={item.id}
                    >
                      <img
                        alt=""
                        src={process.env.REACT_APP_API_HOST + item.image}
                      />
                    </a>
                  );
                })}
              </div>
            )}
          </Fancybox>
        )}
      </div>
    </Page>
  );
};
