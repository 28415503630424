import { useTranslation } from "react-i18next";

export const ResultModal = ({ title, text, isError = false, children }) => {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="modal__title">{title || t("congratulations")}</h2>
      <p className="modal__text">{text}</p>
      <img
        className="modal__img"
        src={`/assets/image/${isError ? "error" : "cup"}.png`}
        alt=""
      />
      {children}
    </>
  );
};
