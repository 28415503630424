import { useCallback, useState } from "react";

export const useModal = () => {
  const [active, setActive] = useState(false);
  const [children, setChildren] = useState("");
  const [classes, setClasses] = useState([]);

  const start = useCallback(() => {
    setActive(true);
  }, []);

  const destroy = useCallback(() => {
    setActive(false);
    // setTimeout(() => {
    // setChildren('')
    // setClasses([])
    // }, 500)
  }, []);

  const reset = useCallback(() => {
    setChildren("");
    setClasses([]);
  }, []);

  return {
    start,
    destroy,
    reset,
    setChildren,
    setClasses,
    classes,
    active,
    children,
  };
};
