import { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { RegisterForm } from "../RegisterForm";
import { LoginModal } from "./LoginModal";
import { useTranslation } from "react-i18next";

export const RegisterModal = () => {
  const modal = useContext(ModalContext);
  const { t } = useTranslation();
  const [method, setMethod] = useState("telegram");

  return (
    <>
      <div>
        {method === "email" ? (
          <button
            className="btn btn-square modal__back-btn"
            onClick={() => setMethod("telegram")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              width="24"
              height="24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
          </button>
        ) : null}
        <h2 className="modal__title">{t("signup")}</h2>
      </div>
      <RegisterForm method={method} setMethod={setMethod} />
      <div className="modal__footer mt-4">
        {t("have_account")}&nbsp;
        <a
          className="link"
          href="!#"
          onClick={(e) => {
            e.preventDefault();
            modal.reset();
            modal.setClasses(["login"]);
            modal.setChildren(<LoginModal />);
          }}
        >
          {t("login")}
        </a>
      </div>
    </>
  );
};
