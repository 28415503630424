import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Page } from "../../components/Layout/Page";
import { useIsMobile } from "../../hooks/isMobile.hook";
import "./style.scss";

export const FaqPage = () => {
  const [currentKey, setCurrentKey] = useState("*");
  const [currentIds, setCurrentIds] = useState([1]);
  const isMobile = useIsMobile();

  const { key, id } = useParams();

  useEffect(() => {
    if (key && id) {
      setCurrentKey(key);
      setCurrentIds((prev) => [Number(id), ...prev]);
    }
  }, [key, id]);

  const { t } = useTranslation();

  const getFaqMenuItem = (item, isClick = false) => {
    return (
      <div
        key={item.key}
        className={`faq-menu__item ${item.key === currentKey && "active"}`}
        onClick={() => {
          isClick && setCurrentKey(item.key);
        }}
      >
        <i className={item.icon}></i>
        <span className="faq-menu__item-text">{item.title}</span>
      </div>
    );
  };

  return (
    <Page>
      <main className="main inner">
        <div className="page-title">{t("page.faq.title")}</div>
        <div className="faq">
          <div className="faq-menu">
            {isMobile ? (
              <Swiper
                slidesPerView={"auto"}
                slideActiveClass="swiper-slide-active-faq"
                centeredSlides={true}
                onSlideChange={({ activeIndex }) => {
                  const current = t("faq.menu", { returnObjects: true }).filter(
                    (_, i) => i === activeIndex,
                  )[0];
                  setCurrentKey(current.key);
                }}
              >
                {t("faq.menu", { returnObjects: true }).map((item) => (
                  <SwiperSlide key={item.title}>
                    {getFaqMenuItem(item)}
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              t("faq.menu", { returnObjects: true }).map((item) =>
                getFaqMenuItem(item, true),
              )
            )}
          </div>
          <div className="faq-question">
            {t("faq.questions", { returnObjects: true })
              .filter((question) => question.keys.includes(currentKey))
              .map((question) => (
                <div
                  key={question.id}
                  className={`faq-question__item ${
                    currentIds.includes(question.id) && "active"
                  }`}
                  onClick={() =>
                    setCurrentIds((prev) =>
                      prev.includes(question.id)
                        ? prev.filter((item) => item !== question.id)
                        : [question.id, ...prev],
                    )
                  }
                >
                  <div className="faq-question__item-header">
                    <span className="faq-question__title">
                      {question.title}
                    </span>
                    <span className="faq-question__arrow">
                      <i className="icon-arrow-down"></i>
                    </span>
                  </div>
                  <div className="faq-question__item-text">
                    {question.reply}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>
    </Page>
  );
};
