import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CardItem } from "./CardItem";
// import 'swiper/swiper-bundle.min.css'
import "swiper/swiper.min.css";

export const FAQPopular = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="faq-card">
      <Swiper
        spaceBetween={13}
        slidesPerView={1}
        grabCursor={true}
        breakpoints={{
          650: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 3,
          },
        }}
      >
        {t("popular_faqs", { returnObjects: true }).map((faq) => (
          <SwiperSlide key={faq.id}>
            <CardItem
              imgOptions={{ classes: faq.classes.join(" "), src: faq.img }}
              title={faq.title}
              text={faq.text}
              col={0}
              onClick={() => {
                history.push(`/faq/${faq.key}/${faq.id}`);
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
