import { Trans, useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import "./style.scss";

export const ReferralPage = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <main className="main inner">
        <div className="page-title">{t("referral_system.title")}</div>
        <div className="regular-post">
          <div className="regular-post__card">
            <div className="regular-post__img">
              <img
                src={t("referral_system.img")}
                alt={t("referral_system.title")}
              />
            </div>
            <div className="regular-post__quote">
              {t("referral_system.quote")}
            </div>
          </div>
          {t("referral_system.textList", { returnObjects: true }).map(
            (text) => (
              <Trans defaults={text} components={{ p: <p /> }} />
            ),
          )}
        </div>
      </main>
    </Page>
  );
};
