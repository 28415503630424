import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "../../components/Chart";
import { Page } from "../../components/Layout/Page";
import { StatisticPercent } from "../../components/StatisticPercent";
import { AuthContext } from "../../context/AuthContext";
import { useHttp } from "../../hooks/http.hook";
import { useIsMobile } from "../../hooks/isMobile.hook";
import { useShuffle } from "../../hooks/shuffle.hook";
import "./style.scss";

export const StatisticPage = () => {
  const { request } = useHttp();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const { shuffle } = useShuffle();
  const isMobile = useIsMobile();

  const [matches, setMatches] = useState([]);
  const [percent, setPercent] = useState({ win: 0, lose: 0 });
  const [page, setPage] = useState(1);
  const limit = 7;
  const [end, setEnd] = useState(false);

  const getDay = (date) => {
    let day = new Date(date).getDate();
    if (day < 10) {
      day = "0" + day;
    }
    return day;
  };

  const getMonth = (date) => {
    let month = new Date(date).getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    return month;
  };

  const getStatistics = useCallback(async () => {
    try {
      const response = await request(
        `/match/closed?limit=${limit}&page=${page}`
      );
      setMatches((prev) => [...prev, ...response.matches]);
      const count = 100 / (response.win + response.lose);
      setPercent({
        win: response.win * count,
        lose: response.lose * count,
      });
      setEnd(response.win + response.lose);
    } catch (e) {}
  }, [page]);

  useEffect(() => getStatistics(), [getStatistics]);

  return (
    <Page>
      <main className="main inner">
        <div className="page-title">Statistics</div>
        <div className="stat-page">
          {!isMobile && (
            <>
              <div className="backlight backlight-blue"></div>
              <div className="backlight backlight-green"></div>
            </>
          )}
          <img
            className="promo__img"
            src="/assets/image/money-in-hand-desctop.png"
            alt="Money in hand"
          />
          <div className="stat-page__percent">
            {!isMobile && (
              <div className="stat-page__title">{t("matches")}</div>
            )}
            <StatisticPercent win={percent.win} lose={percent.lose} />
          </div>
          <div className="stat-page__chart">
            <Chart percent={percent} innerRadius={45} outerRadius={80} />
            {isMobile && <div className="stat-page__title">{t("matches")}</div>}
            <div className="statistic__label">
              <span className="win">
                <span></span>
                {t("win")}
              </span>
              <span className="lose">
                <span></span>
                {t("lose")}
              </span>
            </div>
          </div>
        </div>
        <section className="overflow-x-auto">
          <table>
            <thead>
              <tr>
                <td>{t("date")}</td>
                <td>{t("country")}</td>
                <td>{t("teams")}</td>
                <td className="info">{t("info")}</td>
                <td>{t("odds")}</td>
                <td className="result">
                  {t("win")}/{t("lose")}
                </td>
              </tr>
            </thead>
            <tbody>
              {matches.map((match) => (
                <tr key={match.id}>
                  <td>{getDay(match.date) + "." + getMonth(match.date)}</td>
                  <td>
                    <img
                      className="lang-icon"
                      src={
                        process.env.REACT_APP_API_HOST +
                        "/flags/" +
                        match.country.iso +
                        ".png"
                      }
                      alt="Flag"
                      title="Country"
                    />
                  </td>
                  <td className="teams">
                    {auth.isAuth ? (
                      match.teams
                    ) : (
                      <>
                        <span className="blur">{shuffle(match.teams)}</span>
                        <span className="no_visible">
                          {t("register_first")}
                        </span>
                      </>
                    )}
                  </td>
                  <td className="info">
                    {auth.isAuth ? (
                      match.info
                    ) : (
                      <>
                        <span className="blur">{shuffle(match.info)}</span>
                        <span className="no_visible">
                          {t("register_first")}
                        </span>
                      </>
                    )}
                  </td>
                  <td>{match.odds}</td>
                  <td className="result">
                    {match.result ? (
                      <>
                        <i className="icon-win"></i>
                        {!isMobile && <span className="win">{t("win")}</span>}
                      </>
                    ) : (
                      <>
                        <i className="icon-lose"></i>
                        {!isMobile && <span className="lose">{t("lose")}</span>}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        {end > matches.length && (
          <button
            className="btn btn-accent"
            style={{ margin: "20px auto" }}
            onClick={() => {
              setPage((prev) => prev + 1);
            }}
          >
            {t("show_more")}
          </button>
        )}
      </main>
      <div className="stat-page__promo" style={{ marginTop: 20 }}>
        <div className="backlight backlight-turquoise promo-contact__decoration-one"></div>
        <div className="backlight backlight-turquoise promo-contact__decoration-two"></div>
        <div className="backlight backlight-turquoise promo-contact__decoration-three"></div>
        <div className="backlight backlight-turquoise promo-contact__decoration-four"></div>
        <img src="/assets/image/phone-in-hand-2.png" alt="Phone in hand" />
        <p>
          At first glance, one might think that statistics are not required for
          fixing matches, but this is not the case. We have never promised „100%
          passage“ of our events, because the human factor is present absolutely
          everywhere. It's not robots running around the field. That is why we
          provide statistics for all our events.
        </p>
      </div>
    </Page>
  );
};
