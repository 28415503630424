import { Footer } from "../Footer";

export const Page = ({ children }) => {
  return (
    <div className="page">
      {children}
      <Footer />
    </div>
  );
};
