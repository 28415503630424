import { useCallback, useEffect, useState } from "react";
import { useHttp } from "./http.hook";

export const useNotifi = (token) => {
  const [trigger, setTrigger] = useState(false);
  const [notifics, setNotifics] = useState({ unread_count: 0, list: [] });
  const [active, setActive] = useState(false);
  const { request } = useHttp();

  const getNotifics = useCallback(async () => {
    if (!token) return;

    try {
      const response = await request("/notifi", "GET", token);
      setNotifics(response);
      setActive(true);
    } catch (e) {}
  }, [request, token]);

  useEffect(() => getNotifics(), [getNotifics, trigger]);

  const readNotifics = async () => {
    const ids = notifics.list
      .filter((notific) => !notific.viewed)
      .map((notific) => notific.id);
    setActive(false);
    if (!ids.length) return;

    try {
      await request("/notifi/read", "PUT", token, { read: ids });
      setNotifics((prev) => ({
        ...prev,
        unread_count: 0,
        list: prev.list.map((item) => ({ ...item, viewed: true })),
      }));
    } catch (e) {}
  };

  return {
    notifics,
    readNotifics,
    active,
    trigger: () => setTrigger((prev) => !prev),
  };
};
