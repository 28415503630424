import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ControlledInputs } from "../../components/ControlledInputs";
import { Page } from "../../components/Layout/Page";
import { SettingInputs } from "../../components/SettingInputs";
import { AuthContext } from "../../context/AuthContext";
import { useHttp } from "../../hooks/http.hook";

export const ReportsAdmin = () => {
  const { request, loading } = useHttp();
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const uploadFile = async (file, parent = null) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      if (parent) {
        formData.append("parent", parent);
      }

      const response = await request("/upload", "POST", token, formData);
      return response.path;
    } catch (e) {
      return null;
    }
  };

  const onSubmit = async (data, request, reset) => {
    try {
      const obj = {
        name: data.name,
        date: data.date,
        command_1_name: data.command_1_name,
        command_2_name: data.command_2_name,
        images: [],
      };

      obj.command_1_file = await uploadFile(
        data.command_1_logo[0],
        "reports/commands",
      );
      obj.command_2_file = await uploadFile(
        data.command_2_logo[0],
        "reports/commands",
      );
      obj.video = await uploadFile(data.video[0], "reports/videos");

      for (let i = 0; i < data.images.length; i++) {
        obj.images.push(await uploadFile(data.images[i], "reports/images"));
      }

      await request("/reports", "POST", token, obj);

      reset();
      history.push("/reports");
    } catch (e) {}
  };

  const fieldsMain = [
    {
      name: "name",
      icon: "",
      input: {
        type: "text",
        placeholder: "Name",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "date",
      icon: "",
      input: {
        type: "date",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
  ];

  const fieldCommandOne = [
    {
      name: "command_1_name",
      icon: "",
      input: {
        type: "text",
        placeholder: "Command name",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "command_1_logo",
      icon: "",
      input: {
        type: "file",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
  ];

  const fieldCommandTwo = [
    {
      name: "command_2_name",
      icon: "",
      input: {
        type: "text",
        placeholder: "Command name",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "command_2_logo",
      icon: "",
      input: {
        type: "file",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
  ];

  const fieldVideo = [
    {
      name: "video",
      icon: "",
      input: {
        type: "file",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
  ];

  const fieldImages = [
    {
      name: "images",
      icon: "",
      input: {
        type: "file",
        disabled: loading,
        multiple: true,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
  ];
  return (
    <Page>
      <main className="main">
        <div className="page-title">Add reports</div>
        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data, request, reset);
          })}
        >
          <SettingInputs>
            <ControlledInputs
              fields={fieldsMain}
              register={register}
              errors={errors}
            />
          </SettingInputs>
          <div className="page-title">Command 1 info</div>
          <SettingInputs>
            <ControlledInputs
              fields={fieldCommandOne}
              register={register}
              errors={errors}
            />
          </SettingInputs>
          <div className="page-title">Command 2 info</div>
          <SettingInputs>
            <ControlledInputs
              fields={fieldCommandTwo}
              register={register}
              errors={errors}
            />
          </SettingInputs>
          <div className="page-title">Add video</div>
          <SettingInputs>
            <ControlledInputs
              fields={fieldVideo}
              register={register}
              errors={errors}
            />
          </SettingInputs>
          <div className="page-title">Add images</div>
          <SettingInputs>
            <ControlledInputs
              fields={fieldImages}
              register={register}
              errors={errors}
            />
          </SettingInputs>
          <button className="btn btn-accent">Save</button>
        </form>
      </main>
    </Page>
  );
};
