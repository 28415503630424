import { useCallback, useContext, useEffect, useState } from "react";
import { Page } from "../../components/Layout/Page";
import { MessageForm } from "../../components/MessageForm";
import { Loader } from "../../components/UI/Loader/Loader";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { useHttp } from "../../hooks/http.hook";

export const OrderAdmin = () => {
  const [orders, setOrders] = useState([]);
  const [triggerOrders, setTriggerOrders] = useState(0);

  const { request, loading } = useHttp();
  const { token } = useContext(AuthContext);
  const modal = useContext(ModalContext);

  const getOrders = useCallback(async () => {
    try {
      const response = await request("/order", "GET", token);
      setOrders(response);
    } catch (e) {}
  }, [request, token]);

  useEffect(() => getOrders(), [getOrders, triggerOrders]);

  const viewDetails = async (id) => {
    modal.setChildren(<Loader />);
    modal.start();
    try {
      const match = await request(`/match/${id}`, "GET", token);

      modal.setChildren(
        <>
          <div className="input-control">
            <input type="text" placeholder={match.country.iso} disabled />
          </div>
          <div className="input-control">
            <input type="text" placeholder={match.teams} disabled />
          </div>
          <div className="input-control">
            <input type="text" placeholder={match.info} disabled />
          </div>
          <div className="input-control">
            <input type="text" placeholder={match.league} disabled />
          </div>
          <div className="input-control">
            <input type="text" placeholder={match.odds} disabled />
          </div>
          <div className="input-control">
            <input type="text" placeholder={match.date} disabled />
          </div>
          <div className="input-control">
            <input type="text" placeholder={match.time} disabled />
          </div>
        </>
      );
    } catch (e) {}
  };

  const changeStatus = async (id, status) => {
    if (!status) return;

    try {
      await request(`/order/${id}`, "PUT", token, { status: !!Number(status) });
      setTriggerOrders((prev) => prev + 1);
    } catch (e) {}
  };

  return (
    <Page>
      <main className="main">
        <div className="page-title">Orders</div>
        <section className="overflow-x-auto">
          <table>
            <thead>
              <tr>
                <td>ID</td>
                <td>E-mail</td>
                <td>Telegram</td>
                <td>Price</td>
                <td>Payment</td>
                <td>Details</td>
                <td>Message</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, i) => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>
                    <a href={`mailto://${order.user.email}`}>
                      {order.user.email}
                    </a>
                  </td>
                  <td>
                    <a
                      href={`https://t.me/${order.user.telegram}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {order.user.telegram}
                    </a>
                  </td>
                  <td>${order.price}</td>
                  <td>
                    <a
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                      href={
                        process.env.REACT_APP_BLOCKCHAIN_TX_URL +
                        order.transaction
                      }
                    >
                      Check
                    </a>
                  </td>
                  <td>
                    <span
                      className="link"
                      onClick={() => viewDetails(order.matchId)}
                    >
                      Details
                    </span>
                  </td>
                  <td>
                    <span
                      className="link"
                      onClick={() => {
                        modal.setChildren(
                          <MessageForm
                            title={`Message for @${order.user.telegram}`}
                            userId={order.user.id}
                            beforeFunc={() => {
                              modal.destroy();
                            }}
                          />
                        );
                        modal.start();
                      }}
                    >
                      Write message
                    </span>
                  </td>
                  <td>
                    <select
                      onChange={(e) => changeStatus(order.id, e.target.value)}
                      disabled={loading}
                      value=""
                    >
                      <option value="">...</option>
                      <option value="1">Done</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </Page>
  );
};
