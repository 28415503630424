export const useShuffle = () => {
  const shuffle = (str) => {
    return str
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");
  };

  return { shuffle };
};
