export const InputLabel = ({ icon = "key", setting = {}, error }) => {
  return (
    <div className="input-label">
      <div className="input-control">
        <i className={`icon-${icon}`}></i>
        <input
          {...{
            type: "text",
            ...setting,
          }}
        />
      </div>
      {error && <div className="input-error">{error}</div>}
    </div>
  );
};
