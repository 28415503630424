import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHttp } from "../hooks/http.hook";

export const MessageForm = ({ title, userId, beforeFunc }) => {
  const [message, setMessage] = useState("");
  const { request, loading } = useHttp();
  const { token } = useContext(AuthContext);

  const send = async () => {
    try {
      await request("/message", "POST", token, {
        message,
        userId,
      });
      beforeFunc();
    } catch (e) {}
  };

  return (
    <div>
      <p style={{ textAlign: "center", marginBottom: 20 }}>{title}</p>
      <textarea
        className="input"
        onChange={(e) => setMessage(e.target.value)}
      />
      <button
        className="btn btn-secondary"
        onClick={send}
        disabled={loading || !message.length}
      >
        Send
      </button>
    </div>
  );
};
