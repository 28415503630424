import { Trans, useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import "./style.scss";

export const PartneringPage = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <main className="main inner">
        <div className="page-title">{t("partnering.title")}</div>
        <div className="regular-post">
          <div className="regular-post__card">
            <div className="regular-post__img">
              <img src={t("partnering.img")} alt={t("partnering.title")} />
            </div>
            <div className="regular-post__quote">{t("partnering.quote")}</div>
          </div>
          {t("partnering.textList", { returnObjects: true }).map((text) => (
            <Trans defaults={text} components={{ p: <p /> }} />
          ))}
        </div>
      </main>
    </Page>
  );
};
