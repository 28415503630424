import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHttp } from "../hooks/http.hook";

export const BOAccountForm = ({ account, onDone } = {}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();
  const [countries, setCountries] = useState([]);
  const { t } = useTranslation();
  const { request } = useHttp();
  const { token } = useContext(AuthContext);

  const getCountries = useCallback(async () => {
    try {
      setCountries(await request("/country", "GET", token));
    } catch (e) {}
  }, [request, token]);

  const onSave = useCallback(
    async (data) => {
      try {
        const response = await request("/bo-accounts", "POST", token, data);
        onDone(response);
      } catch (e) {}
    },
    [request]
  );

  const onUpdate = useCallback(
    async (data) => {
      try {
        const response = await request(
          `/bo-accounts/${account.id}`,
          "PATCH",
          token,
          data
        );
        onDone(response);
      } catch (e) {}
    },
    [request, account]
  );

  const onSubmit = account ? onUpdate : onSave;

  useEffect(() => getCountries(), [getCountries]);
  useEffect(() => {
    reset(account);
  }, [account]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="bo-account-form">
      <div className="bo-account-form__container">
        <label className="form-label">
          Country
          <select
            className="form-control"
            {...register("country_id", {
              required: t("errors.input.required"),
            })}
          >
            {countries.map((country, idx) => (
              <option value={country.id} key={idx}>
                {country.name}
              </option>
            ))}
          </select>
          {errors["country_id"] ? (
            <small className="form-control__error">
              {errors["country_id"].message}
            </small>
          ) : null}
        </label>
        <label className="form-label">
          Name
          <input
            {...register("name", {
              required: t("errors.input.required"),
            })}
            placeholder="BetFair"
            className="form-control"
          />
          {errors["name"] ? (
            <small className="form-control__error">
              {errors["name"].message}
            </small>
          ) : null}
        </label>
        <label className="form-label">
          Price
          <input
            {...register("price", {
              required: t("errors.input.required"),
            })}
            placeholder="500.0"
            className="form-control"
          />
          {errors["price"] ? (
            <small className="form-control__error">
              {errors["price"].message}
            </small>
          ) : null}
        </label>
        <label className="form-label">
          Comission
          <input
            {...register("comission", {
              required: t("errors.input.required"),
            })}
            placeholder="12.0"
            className="form-control"
          />
          {errors["comission"] ? (
            <small className="form-control__error">
              {errors["comission"].message}
            </small>
          ) : null}
        </label>
        <label className="form-label">
          Withdrawal fee
          <input
            {...register("withdrawal_fee", {
              required: t("errors.input.required"),
            })}
            placeholder="8.0"
            className="form-control"
          />
          {errors["withdrawal_fee"] ? (
            <small className="form-control__error">
              {errors["withdrawal_fee"].message}
            </small>
          ) : null}
        </label>
        <div>
          <span className="form-label">Availability</span>
          <div className="form-checkbox-container">
            <Controller
              name="available"
              defaultValue={false}
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  type="checkbox"
                  className="form-checkbox"
                  checked={field.value || false}
                  id="available"
                  onChange={(e) => setValue("available", e.target.checked)}
                />
              )}
            />
            <label htmlFor="available" className="form-checkbox-label">
              Available
            </label>
          </div>
        </div>
      </div>
      <button className="btn btn-accent">{account ? "Save" : "Add"}</button>
    </form>
  );
};
