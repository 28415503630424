import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import { LoaderMini } from "../../components/UI/Loader/LoaderMini";
import { AuthContext } from "../../context/AuthContext";
import { useHttp } from "../../hooks/http.hook";
import "./style.scss";

export const BalancePage = () => {
  const auth = useContext(AuthContext);
  const { request, loading } = useHttp();
  const { t } = useTranslation();
  const [copying, setCopying] = useState();
  const [match, setMatch] = useState({});
  const [convertedPrices, setConvertedPrices] = useState({});

  const getMatch = useCallback(async () => {
    try {
      const response = await request("/match", "GET");
      setMatch(response);
    } catch (e) {}
  }, [request]);

  const getConvertedMatchPrice = useCallback(async (price) => {
    try {
      const response = await request(
        `/exchange-rates/USD?targets=BTC&targets=USDT&value=${price}`,
        "GET"
      );
      setConvertedPrices(response);
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (match) getConvertedMatchPrice(match.price);
  }, [match, getConvertedMatchPrice]);

  useEffect(() => getMatch(), [getMatch]);

  const update = useCallback(
    async (id) => {
      try {
        const response = await request(
          `/wallets/${id}/balance`,
          "GET",
          auth.token
        );
        console.log(response);
      } catch (e) {}
    },
    [request]
  );

  const walletHTML = (wallet, i) => {
    return (
      <div className="wallet__wrapper" key={i}>
        <p className="wallet__title">
          {t("page.balance.your_address", { currency: wallet.currency.name })}
        </p>
        <div
          className={
            copying === wallet.id ? "wallet__inner active" : "wallet__inner"
          }
          onClick={() => {
            setCopying(wallet.id);
            window.navigator.clipboard.writeText(wallet.wallet);
            setTimeout(() => setCopying(null), 1000);
          }}
        >
          <div className="wallet__adress">{wallet.wallet}</div>
          <i className="icon-copy"></i>
        </div>
      </div>
    );
  };

  return (
    <Page>
      <div className="main inner">
        <div className="page-title">{t("page.balance.title")}</div>
        <div className="money__container">
          {auth.wallets.map((wallet, i) => (
            <div className="balance__wrapper" key={i}>
              <div className="balance__title">{t("your_balance")}</div>
              <div className="balance__bitcoin">
                <img
                  style={{ width: "1.5rem" }}
                  src={wallet.currency.logo_data_url}
                />
                <span>{wallet.balance / wallet.currency.one_unit}</span>
              </div>
              <div className="balance__dollar">
                {/* ≈${(auth.balance * course).toFixed(2)} */}
              </div>
              <button
                className="btn btn-accent"
                disabled={loading}
                onClick={() => update(wallet.id)}
              >
                {t("update")}
              </button>
              <img
                className="balance__img"
                src="/assets/image/wallet.png"
                alt=""
              />
            </div>
          ))}
        </div>
        {auth.wallets.map(walletHTML)}
      </div>
      <div className="main inner">
        <h2>{t("balance_refill.title")}</h2>
        <div className="balance-refill__header header-balance-refill">
          <div className="header-balance-refill__item">
            <div className="header-balance-refill__label">
              <Trans
                defaults={t("page.balance.no_crypto")}
                components={{ span: <span /> }}
              />
            </div>
          </div>
          <div className="header-balance-refill__item">
            <div className="header-balance-refill__label">
              {t("page.balance.do_you_have")}&nbsp;
              {auth.wallets.map((wallet, i) => (
                <>
                  <div key={i}>
                    <img
                      src={wallet.currency.logo_data_url}
                      style={{ width: "1.5rem" }}
                    />
                  </div>
                  {i < auth.wallets.length - 1 ? (
                    <span className="or mx-2">{t("page.balance.or")}</span>
                  ) : null}
                </>
              ))}
            </div>
            <img src="/assets/image/two_arrows.png" alt="" />
          </div>
          <div className="header-balance-refill__item">
            <div className="header-balance-refill__label">
              <Trans
                defaults={t("page.balance.yes_btc")}
                components={{ span: <span /> }}
              />
            </div>
          </div>
        </div>
        <div className="balance-refill__body">
          <div className="balance-refill__left">
            <img src="/assets/image/balance-arrow-left.png" alt="" />
          </div>
          <div className="balance-refill__list">
            <div className="balance-refill__inner">
              <h2>{t("page.balance.registered_on_crypto_exchange")}</h2>
              <div className="balance-refill__images">
                <a
                  href="https://www.coinbase.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/image/coinbase.png" alt="" />
                </a>
                <a
                  href="https://www.binance.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/image/binance.png" alt="" />
                </a>
              </div>
            </div>
            <div className="balance-refill__inner">
              <h2>{t("page.balance.purchase")}</h2>
              <button className="btn btn-accent btn-buy" disabled={loading}>
                <span className="btn-buy-text">{t("price")}</span>
                <span className="match__price">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 339.43 295.27"
                    width="20"
                    className="mr-2"
                  >
                    <path
                      fill="#50af95"
                      fillRule="evenodd"
                      d="m62.15 1.45-61.89 130a2.52 2.52 0 0 0 .54 2.94l167.15 160.17a2.55 2.55 0 0 0 3.53 0L338.63 134.4a2.52 2.52 0 0 0 .54-2.94l-61.89-130A2.5 2.5 0 0 0 275 0H64.45a2.5 2.5 0 0 0-2.3 1.45Z"
                    />
                    <path
                      d="M191.19 144.8c-1.2.09-7.4.46-21.23.46-11 0-18.81-.33-21.55-.46-42.51-1.87-74.24-9.27-74.24-18.13s31.73-16.25 74.24-18.15v28.91c2.78.2 10.74.67 21.74.67 13.2 0 19.81-.55 21-.66v-28.9c42.42 1.89 74.08 9.29 74.08 18.13s-31.65 16.24-74.08 18.12Zm0-39.25V79.68h59.2V40.23H89.21v39.45h59.19v25.86c-48.11 2.21-84.29 11.74-84.29 23.16s36.18 20.94 84.29 23.16v82.9h42.78v-82.93c48-2.21 84.12-11.73 84.12-23.14s-36.09-20.93-84.12-23.15Zm0 0Z"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                  </svg>
                  <span>{loading ? <LoaderMini /> : match?.price}</span>
                </span>
                <span className="match__price">
                  <i className="icon-bitcoin"></i>
                  <span>{loading ? <LoaderMini /> : convertedPrices.BTC}</span>
                </span>
              </button>
            </div>
            <div className="balance-refill__inner">
              <h2>{t("page.balance.transfer")}</h2>
              <ul className="wallet__list">
                {auth.wallets.map((wallet, i) => (
                  <li key={i} className="wallet__address-container">
                    <img
                      src={wallet.currency.logo_data_url}
                      style={{ width: "1.5rem" }}
                    />
                    <div
                      className={
                        copying === wallet.id
                          ? "wallet__inner active"
                          : "wallet__inner"
                      }
                      onClick={() => {
                        setCopying(wallet.id);
                        window.navigator.clipboard.writeText(wallet.wallet);
                        setTimeout(() => setCopying(null), 1000);
                      }}
                    >
                      <div className="wallet__adress">{wallet.wallet}</div>
                      <i className="icon-copy"></i>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="balance-refill__inner">
              <h2>{t("page.balance.update_your_balance")}</h2>
              <button
                className="btn btn-accent"
                disabled={loading}
                onClick={() => auth.wallets.forEach((w) => update(w.id))}
              >
                {t("update")}
              </button>
            </div>
          </div>
          <div className="balance-refill__right">
            <img src="/assets/image/balance-arrow-right.png" alt="" />
          </div>
        </div>
      </div>
    </Page>
  );
};
