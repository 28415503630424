import { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ControlledInputs } from "../../components/ControlledInputs";
import { Page } from "../../components/Layout/Page";
import { ResultModal } from "../../components/Modal/ResultModal";
import { SettingCard } from "../../components/SettingCard";
import { SettingInputs } from "../../components/SettingInputs";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { useHttp } from "../../hooks/http.hook";
import { useIsMobile } from "../../hooks/isMobile.hook";
import "./style.scss";

export const SettingPage = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { request } = useHttp();
  const [copy, setCopy] = useState(false);
  const refLink = useRef();
  const { t } = useTranslation();
  const modal = useContext(ModalContext);
  const isMobile = useIsMobile();

  const {
    register: telegramRegister,
    handleSubmit: handlerTelegramSubmit,
    formState: { errors: telegramError },
    reset: resetTelegram,
  } = useForm();

  const {
    register: emailRegister,
    handleSubmit: handlerEmailSubmit,
    formState: { errors: emailError },
    reset: resetEmail,
  } = useForm();

  const {
    register: passwordRegister,
    handleSubmit: handlerPasswordSubmit,
    formState: { errors: passwordError },
    reset: resetPassword,
  } = useForm();

  const change = async (data, key) => {
    try {
      const response = await request("/user", "PUT", auth.token, {
        [key]: data[key],
      });
      auth.login(response.token);
      resetTelegram();
      resetEmail();
    } catch (e) {}
  };

  const changePassword = async (data) => {
    if (data.new_password !== data.repeat_password) {
      modal.setClasses(["error"]);
      modal.setChildren(
        <ResultModal
          isError={true}
          title={t("errors.title")}
          text={t("page.setting.no_repeat_password")}
        />
      );
      modal.start();
      return;
    }

    try {
      await request("/user", "PUT", auth.token, {
        password: data.password,
        new_password: data.new_password,
      });

      modal.setClasses(["success"]);
      modal.setChildren(
        <ResultModal text={t("page.setting.password_update")} />
      );
      modal.start();
      resetPassword();
    } catch (e) {
      modal.setClasses(["error"]);
      modal.setChildren(
        <ResultModal
          isError={true}
          title={t("errors.title")}
          text={t(e.message)}
        />
      );
      modal.start();
    }
  };

  const telegramField = {
    name: "telegram",
    icon: "telegram-circle",
    input: {
      type: "text",
      placeholder: t("placeholder.your_telegram"),
    },
    validate: {
      required: t("errors.input.required"),
    },
  };

  const emailField = {
    name: "email",
    icon: "email-circle",
    input: {
      type: "text",
      placeholder: t("placeholder.your_email"),
    },
    validate: {
      required: t("errors.input.required"),
      pattern: {
        value: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
        message: t("errors.input.email"),
      },
    },
  };

  const passwordFields = [
    {
      name: "password",
      icon: "key",
      input: {
        type: "password",
        placeholder: t("placeholder.your_password"),
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "new_password",
      icon: "key",
      input: {
        type: "password",
        placeholder: t("placeholder.new_password"),
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "repeat_password",
      icon: "key",
      input: {
        type: "password",
        placeholder: t("placeholder.repeat_password"),
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
  ];

  return (
    <Page>
      <main className="main inner no-inner">
        <div className="page-title">{t("page.setting.title")}</div>
        <div className="setting-user">
          <div className="setting-avatar">
            <img
              className="setting-avatar__img"
              src="/assets/image/user.png"
              alt="Avatar"
            />
            <div className="setting-avatar__name">
              {auth.email?.split("@")[0]}
            </div>
            <div className="backlight backlight-blue"></div>
          </div>
          <div className="setting-info">
            <div className="setting-info__data">
              <div className="setting-info__title">{t("label.telegram")}</div>
              <div className="setting-info__name">@{auth.telegram}</div>
            </div>
            <div className="setting-info__data">
              <div className="setting-info__title">{t("label.email")}</div>
              <div className="setting-info__name">{auth.email}</div>
            </div>
            {!isMobile && (
              <img
                className="setting-info__img"
                src="/assets/image/phone-in-hand.png"
                alt="phone-in-hand"
              />
            )}
            <div className="backlight backlight-blue"></div>
            <div className="backlight backlight-green"></div>
          </div>
        </div>
        <div className="main inner">
          <div className="page-title">{t("change_data")}</div>
          <div className="setting-cards">
            <SettingCard
              title={t("label.telegram")}
              buttonName={t("change")}
              form={{
                onSubmit: handlerTelegramSubmit((data) =>
                  change(data, "telegram")
                ),
              }}
            >
              <ControlledInputs
                fields={[telegramField]}
                register={telegramRegister}
                errors={telegramError}
              />
            </SettingCard>
            <SettingCard
              title={t("label.email")}
              buttonName={t("change")}
              form={{
                onSubmit: handlerEmailSubmit((data) => change(data, "email")),
              }}
            >
              <ControlledInputs
                fields={[emailField]}
                register={emailRegister}
                errors={emailError}
              />
            </SettingCard>
          </div>
          <SettingCard
            title={t("label.password")}
            buttonName={t("change")}
            form={{
              onSubmit: handlerPasswordSubmit(changePassword),
            }}
          >
            <SettingInputs>
              <ControlledInputs
                fields={passwordFields}
                register={passwordRegister}
                errors={passwordError}
              />
            </SettingInputs>
          </SettingCard>
        </div>
      </main>
    </Page>
  );
};
