import { useTranslation } from "react-i18next";

export const CardItem = ({
  imgOptions,
  col = 1,
  title,
  text,
  button = true,
  onClick = () => {},
}) => {
  imgOptions = {
    classes: [],
    src: "",
    ...imgOptions,
  };
  const { t } = useTranslation();

  return (
    <div className={`card__item col-${col}`}>
      <img
        className={"card__img " + imgOptions.classes}
        src={imgOptions.src}
        alt={title}
      />
      <h2 className="card__title">{title}</h2>
      <p className="card__text">{text}</p>
      {button && (
        <button className="btn btn-accent" onClick={onClick}>
          {t("read_more")}
        </button>
      )}
    </div>
  );
};
