import React, { useContext } from "react";
import { ModalContext } from "../../../context/ModalContext";
import "./style.scss";

export const Modal = () => {
  const modal = useContext(ModalContext);

  const classList = ["modal", ...modal.classes];

  if (modal.active) {
    classList.push("active");
  }

  return (
    <div className={classList.join(" ")}>
      <div className="modal__content">
        <span className="modal__close" onClick={modal.destroy}>
          &times;
        </span>
        <div className="backlight backlight-green"></div>
        <div className="backlight backlight-blue"></div>
        {modal.children}
      </div>
    </div>
  );
};
