import { useCallback, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useHttp } from "./http.hook";

const storageName = "userData";

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const [balance, setBalance] = useState(0);
  const [telegram, setTelegram] = useState(null);
  const [email, setEmail] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [wallets, setWallets] = useState([]);
  const [ready, setReady] = useState(false);

  const http = useHttp();

  const login = useCallback(async (jwt) => {
    const jwtParse = jwt_decode(jwt);
    setToken(jwt);
    setBalance(jwtParse.balance / process.env.REACT_APP_SATOSHI_CONVERT);
    setRole(jwtParse.role);
    setTelegram(jwtParse.telegram);
    setEmail(jwtParse.email);
    setWallet(jwtParse.wallet);
    localStorage.setItem(storageName, JSON.stringify({ token: jwt }));
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setBalance(null);
    setRole(null);
    setTelegram(null);
    setEmail(null);
    setWallet(null);
    localStorage.removeItem(storageName);
  }, []);

  const check = useCallback(async () => {
    const data = JSON.parse(localStorage.getItem(storageName));

    try {
      if (data && data.token) {
        const response = await http.request("/user/auth", "GET", data.token);
        login(response.token);
      }
    } catch (e) {
      logout();
    } finally {
      setReady(true);
    }
  }, []);

  const getWallets = useCallback(async () => {
    if (!token) return;
    try {
      const wallets = await http.request("/wallets", "GET", token);
      setWallets(wallets);
    } catch (e) {}
  }, [token]);

  useEffect(() => getWallets(), [getWallets])

  useEffect(() => check(), [check]);

  return {
    login,
    logout,
    token,
    role,
    balance,
    telegram,
    email,
    wallet,
    ready,
    wallets
  };
};
