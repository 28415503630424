import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useIsMobile } from "../../hooks/isMobile.hook";
import { MenuItem } from "./MenuItem";
import { MenuList } from "./MenuList";

import { useTranslation } from "react-i18next";
import "../../i18next";
import { useHistory } from "react-router";
import { ModalContext } from "../../context/ModalContext";
import { LoginModal } from "../Modal/LoginModal";
import { RegisterModal } from "../Modal/RegisterModal";

export const Menu = ({ isMenuOpen, setIsMenuOpen, setIsNotifiOpen }) => {
  const auth = useContext(AuthContext);
  const modal = useContext(ModalContext);
  const isMobile = useIsMobile();
  const history = useHistory();

  const { t } = useTranslation();

  const menu = {
    main: [
      {
        to: "/match",
        icon: "megaphone",
        title: t("menu.main.match"),
        active: true,
      },
      {
        to: "/admin/match",
        icon: "futbol",
        title: t("menu.main.admin.match"),
        active: auth.role === process.env.REACT_APP_ROLE_ADMIN,
      },
      {
        to: "/admin/user",
        icon: "users",
        title: t("menu.main.admin.user"),
        active: auth.role === process.env.REACT_APP_ROLE_ADMIN,
      },
      {
        to: "/admin/order",
        icon: "list",
        title: t("menu.main.admin.order"),
        active: auth.role === process.env.REACT_APP_ROLE_ADMIN,
      },
      {
        to: "/admin/course",
        icon: "bitcoin",
        title: t("menu.main.admin.course"),
        active: auth.role === process.env.REACT_APP_ROLE_ADMIN,
      },
      {
        to: "/balance",
        icon: "wallet",
        title: t("menu.main.balance"),
        active: auth.isAuth,
      },
      {
        to: "/statistic",
        icon: "statistics",
        title: t("menu.main.statistic"),
        active: true,
      },
      {
        to: "/reports",
        icon: "reports",
        title: t("menu.main.reports"),
        active: true,
      },
      {
        to: "/bo-accounts",
        icon: "reports",
        title: t("menu.main.buy_bo_accounts"),
        active: true,
      },
      {
        to: "/admin/reports",
        icon: "file-plus",
        title: t("menu.main.admin.reports"),
        active: auth.role === process.env.REACT_APP_ROLE_ADMIN,
      },
      {
        to: "/ref-links",
        icon: "referral",
        title: t("menu.main.referral_links"),
        active: !!auth.token,
      },
      { to: "/faq", icon: "question", title: t("menu.main.faq"), active: true },
      // {to: '/blog', icon: 'blog', title: t('menu.main.blog'), active: true},
      {
        to: "/contact",
        icon: "contact",
        title: t("menu.main.contact"),
        active: true,
      },
    ],
    extra: [
      {
        to: "/partnering",
        icon: "partnering",
        title: t("menu.extra.partnering"),
      },
      { to: "/referral", icon: "referral", title: t("menu.extra.referral") },
      { to: "/rules", icon: "rules", title: t("menu.extra.rules") },
    ],
  };

  return (
    <nav
      className={isMenuOpen && isMobile ? "menu active" : "menu"}
      onClick={(e) => {
        e.stopPropagation();
        setIsNotifiOpen(false);
      }}
    >
      <MenuList>
        {menu.main
          .filter((item) => item.active)
          .map((item) => (
            <MenuItem
              {...item}
              key={item.to}
              onClick={() => setIsMenuOpen(false)}
            />
          ))}
      </MenuList>
      {!auth.isAuth && isMobile ? (
        <div className="flex flex-col gap-4 p-5 menu__actions">
          <button
            className="btn btn-secondary btn-w-full"
            onClick={() => {
              modal.setClasses(["login"]);
              modal.setChildren(<LoginModal />);
              modal.start();
            }}
          >
            {t("login")}
          </button>
          <button
            className="btn btn-accent btn-w-full"
            onClick={() => {
              modal.setClasses(["login"]);
              modal.setChildren(<RegisterModal />);
              modal.start();
            }}
          >
            {t("registration")}
          </button>
        </div>
      ) : null}
      <div className="menu__extra">
        <button className="btn btn-secondary">{t("menu.parthnership")}</button>
        <MenuList>
          {menu.extra.map((item) => (
            <MenuItem
              {...item}
              key={item.to}
              onClick={() => {
                setIsMenuOpen(false);
                setIsNotifiOpen(false);
              }}
            />
          ))}
        </MenuList>
      </div>
      {isMobile && (
        <div className="menu__actions">
          {auth.isAuth && (
            <div style={{ flex: 1 }}>
              <div className="menu__profile p-8">
                <button className="btn header__avatar">
                  <img
                    className="header__avatar-img"
                    src="/assets/image/avatar.png"
                    alt="Avatar"
                  />
                </button>
                <div className="menu__profile-info">
                  <p>{t("your_balance")}</p>
                  <ul className="flex items-center gap-4 mt-2">
                    {auth.wallets.map((wallet, key) => (
                      <div className="menu__profile-balance" key={key}>
                        <img className="mr-2" src={wallet.currency.logo_data_url} width="20" />
                        <span>{wallet.balance}</span>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex flex-col gap-4 px-8">
                <div
                  className="menu__actions-menu"
                  onClick={() => {
                    setIsMenuOpen(false);
                    history.push("/setting");
                  }}
                >
                  <i className="icon-settings"></i>
                  <span>{t("menu.settings")}</span>
                </div>

                <div
                  className="menu__actions-menu"
                  onClick={() => {
                    auth.logout();
                  }}
                >
                  <i className="icon-logout"></i>
                  <span>{t("menu.logout")}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};
