export const SettingCard = ({ title, form, children, buttonName = "" }) => {
  return (
    <form className="setting-card" {...form}>
      <div className="setting-card__title">{title}</div>
      {children}
      <button className="btn btn-accent" type="submit">
        {buttonName}
      </button>
    </form>
  );
};
