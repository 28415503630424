import { NavLink } from "react-router-dom";

export const MenuItem = ({ to, icon, title, onClick }) => {
  return (
    <li className="menu__item">
      <NavLink to={to} className="menu__link" onClick={onClick}>
        <i className={`icon-${icon}`}></i>
        <span>{title}</span>
      </NavLink>
    </li>
  );
};
