import { useTranslation } from "react-i18next";

export const LangItem = ({ title, code, changeLang }) => {
  const { i18n } = useTranslation();
  return (
    <div className={i18n.language === code ? "lang__item active" : "lang__item"} onClick={() => changeLang(code)}>
      <img src={`/assets/image/lang/${code}.png`} alt="Language selection" />
      {title}
    </div>
  );
};
