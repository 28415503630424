import React from "react";
import { useParams, useLocation, matchPath } from "react-router-dom";
import { Product } from "../../components/page/main/Product";
import { Promo } from "../../components/page/main/Promo";
import "./style.scss";
import { useCallback, useContext, useEffect } from "react";
import { useHttp } from "../../hooks/http.hook";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { StaticticMainPage } from "../../components/StatisticMainPage";
import { FAQPopular } from "../../components/FAQPopular";
// import { BlogLastPosts } from '../../components/BlogLastPosts'
import { Page } from "../../components/Layout/Page";
import { useTranslation } from "react-i18next";
import { ResultModal } from "../../components/Modal/ResultModal";
import { EmailConfirmationModal } from "../../components/Modal/EmailConfirmationModal";
import { ReportsLasts } from "../../components/ReportsLasts";
import { track } from "../../utils";

export const MainPage = () => {
  const { email, hash } = useParams();
  const { request } = useHttp();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const modal = useContext(ModalContext);
  const { t } = useTranslation();

  const verify = useCallback(async () => {
    if (!email || !hash) return;
    try {
      const res = await request(`/user/verify?email=${email}&hash=${hash}`);
      auth.login(res.token);
      modal.setClasses(["success"]);
      modal.setChildren(<ResultModal text={t("account_activated")} />);
      modal.start();
    } catch (e) { }
  }, []);

  const telegramAuth = useCallback(async (location) => {
    if (location.pathname !== "/auth/telegram") return;
    try {
      const response = await request(
        "/user" + location.pathname + location.search,
        "POST"
      );
      auth.login(response.token);
      modal.setClasses(["success"]);
      modal.setChildren(<ResultModal text={t("account_activated")} />);
      modal.start();
    } catch (e) { }
  }, []);

  const getEmailForm = useCallback(async (location) => {
    const match = matchPath(location.pathname, { path: "/user/:id/emails", exact: true });
    if (!match) return;
    try {
      const url = match.url + window.location.search;
      const response = await request(url, "GET");
      modal.setClasses(["success"]);
      modal.setChildren(<EmailConfirmationModal data={response} url={url} />);
      modal.start();
    } catch (e) { }
  }, [])

  useEffect(() => verify(), [verify]);
  useEffect(() => telegramAuth(location), [location]);
  useEffect(() => getEmailForm(location), [location]);

  return (
    <Page>
      <main className="main">
        <div className="info__wrapper">
          <Promo />
          <Product />
        </div>
        <StaticticMainPage />
        <ReportsLasts />
        <FAQPopular />
        {/* <BlogLastPosts /> */}
      </main>
    </Page>
  );
};
