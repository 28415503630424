import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Page } from "../../components/Layout/Page";
import { RegisterModal } from "../../components/Modal/RegisterModal";
import { ResultModal } from "../../components/Modal/ResultModal";
import { LoaderMini } from "../../components/UI/Loader/LoaderMini";
// import { VideoPost } from '../../components/VideoPost'
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { NotifiContext } from "../../context/NotifiContext";
import { useHttp } from "../../hooks/http.hook";
import { useIsMobile } from "../../hooks/isMobile.hook";
import { track } from "../../utils";
import "./style.scss";

export const MatchPage = () => {
  const [match, setMatch] = useState(null);
  const { request, loading } = useHttp();
  const auth = useContext(AuthContext);
  const modal = useContext(ModalContext);
  const notific = useContext(NotifiContext);
  const isMobile = useIsMobile();
  const history = useHistory();
  const { t } = useTranslation();
  const [buyProcess, setBuyProcess] = useState(false);
  const [convertedPrices, setConvertedPrices] = useState({});

  const getMatch = useCallback(async () => {
    try {
      const response = await request("/match", "GET");
      setMatch(response);
    } catch (e) { }
  }, [request]);

  const getConvertedMatchPrice = useCallback(
    async (price) => {
      try {
        const response = await request(
          `/exchange-rates/USD?targets=BTC&targets=USDT&value=${price}`,
          "GET"
        );
        setConvertedPrices(response);
      } catch (e) { }
    },
    [request]
  );

  const update = async () => {
    try {
      const response = await request("/balance", "GET", auth.token);
      auth.login(response.token);
      notific.trigger();
    } catch (e) { }
  };

  useEffect(() => getMatch(), [getMatch]);
  useEffect(() => {
    if (match) getConvertedMatchPrice(match.price);
  }, [match, getConvertedMatchPrice]);

  const getDay = (date) => new Date(date).getDate();
  const getMonth = (date) => new Date(date).getMonth();

  const buy = async () => {
    if (!match) {
      return;
    }

    notific.trigger();
    if (!auth.isAuth) {
      modal.setClasses(["login"]);
      modal.setChildren(<RegisterModal />);
      modal.start();
      return;
    }

    try {
      setBuyProcess(true);
      await request(`/order/${match.id}/buy`, "GET", auth.token);
      modal.setClasses(["buy"]);
      modal.setChildren(
        <ResultModal text={t("buy_success_text", { telegram: auth.telegram })}>
          <p>
            {t("buy_success_desc")}{" "}
            <span
              className="link"
              style={{ textTransform: "lowercase" }}
              onClick={() => {
                history.push("/contact");
                modal.destroy();
              }}
            >
              {t("menu.main.contact")}
            </span>
          </p>
        </ResultModal>
      );
      modal.start();
      notific.trigger();
      setBuyProcess(false);
    } catch (e) {
      modal.setClasses(["error"]);
      modal.setChildren(
        <ResultModal
          title={t("errors.title")}
          text={t(e.message)}
          isError={true}
        >
          <button
            className="btn btn-accent"
            onClick={() => {
              modal.destroy();
              history.push("/contact");
            }}
          >
            {t("menu.main.contact")}
          </button>
        </ResultModal>
      );
      modal.start();
    }
  };

  return (
    <Page>
      <div className="main inner">
        <div className="page-title">{t("page.main.product_title")}</div>
        <div className="match">
          <div className="match__body">
            <div className="match__detail">
              <div>
                <div className="match__title">{t("country")}</div>
                <div className="match__text">
                  {(loading && !buyProcess) || !match ? (
                    <LoaderMini />
                  ) : (
                    match.country.name
                  )}
                </div>
              </div>
              {match?.country && (
                <img
                  src={
                    process.env.REACT_APP_API_HOST +
                    "/flags/" +
                    match.country.iso +
                    ".png"
                  }
                  alt={match.country.iso}
                />
              )}
            </div>
            <div className="match__detail">
              <div>
                <div className="match__title">{t("league")}</div>
                <div className="match__text">
                  {(loading && !buyProcess) || !match ? (
                    <LoaderMini />
                  ) : (
                    match?.league
                  )}
                </div>
              </div>
            </div>
            <div className="match__detail">
              <div>
                <div className="match__title">{t("odds")}</div>
                <div className="match__text">
                  {(loading && !buyProcess) || !match ? (
                    <LoaderMini />
                  ) : (
                    match?.odds
                  )}
                </div>
              </div>
            </div>
            <div className="match__detail">
              <div>
                <div className="match__title">{t("date")}</div>
                <div className="match__text">
                  {(loading && !buyProcess) || !match ? (
                    <LoaderMini />
                  ) : (
                    `${t("months", { returnObjects: true })[getMonth(match.date)]
                    } ${getDay(match.date)}`
                  )}
                </div>
              </div>
            </div>
            <div className="match__detail">
              <div>
                <div className="match__title">{t("time")}</div>
                <div className="match__text">
                  {(loading && !buyProcess) || !match ? (
                    <LoaderMini />
                  ) : (
                    t("until_gtm", {
                      time: match?.time
                        ? match.time.substring(0, match.time.lastIndexOf(":"))
                        : "-",
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="match__footer">
            <button className="btn btn-accent btn-buy" disabled={loading}>
              <span className="btn-buy-text">{t("price")}</span>
              <span className="match__price">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 339.43 295.27"
                  width="20"
                  className="mr-2"
                >
                  <path
                    fill="#50af95"
                    fillRule="evenodd"
                    d="m62.15 1.45-61.89 130a2.52 2.52 0 0 0 .54 2.94l167.15 160.17a2.55 2.55 0 0 0 3.53 0L338.63 134.4a2.52 2.52 0 0 0 .54-2.94l-61.89-130A2.5 2.5 0 0 0 275 0H64.45a2.5 2.5 0 0 0-2.3 1.45Z"
                  />
                  <path
                    d="M191.19 144.8c-1.2.09-7.4.46-21.23.46-11 0-18.81-.33-21.55-.46-42.51-1.87-74.24-9.27-74.24-18.13s31.73-16.25 74.24-18.15v28.91c2.78.2 10.74.67 21.74.67 13.2 0 19.81-.55 21-.66v-28.9c42.42 1.89 74.08 9.29 74.08 18.13s-31.65 16.24-74.08 18.12Zm0-39.25V79.68h59.2V40.23H89.21v39.45h59.19v25.86c-48.11 2.21-84.29 11.74-84.29 23.16s36.18 20.94 84.29 23.16v82.9h42.78v-82.93c48-2.21 84.12-11.73 84.12-23.14s-36.09-20.93-84.12-23.15Zm0 0Z"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </svg>
                <span>
                  {(loading && !buyProcess) || !match ? (
                    <LoaderMini />
                  ) : (
                    match?.price
                  )}
                </span>
              </span>
              <span className="match__price">
                <i className="icon-bitcoin"></i>
                <span>
                  {(loading && !buyProcess) || !match ? (
                    <LoaderMini />
                  ) : (
                    convertedPrices.BTC
                  )}
                </span>
              </span>
            </button>
            <button className="btn btn-accent" onClick={buy} disabled={loading}>
              {t("product_buy")}
            </button>
          </div>
          {!isMobile && (
            <img
              className="match__img"
              src="/assets/image/coins-in-hand.png"
              alt=""
            />
          )}
          <div className="backlight backlight-silver"></div>
        </div>
      </div>
      {/* <VideoPost
        title={t('how_get_information.title')}
        poster={t('how_get_information.poster')}
        src={t('how_get_information.src')}
        textList={t('how_get_information.textList', {returnObjects: true})}
      /> */}
      <div className="main inner">
        <h2>{t("how_get_information.title")}</h2>
        <div className="buy-info">
          <div className="buy-info__row">
            <div className="buy-info__item">
              <button className="btn header__avatar">
                <img
                  className="header__avatar-img"
                  src="/assets/image/avatar.png"
                  alt="Avatar"
                />
              </button>
              <div className="header__actions">
                <div
                  className="user-menu active"
                  style={{ position: "relative" }}
                >
                  <div
                    className="user-menu__item"
                    onClick={() => {
                      if (auth.isAuth) {
                        history.push("/setting");
                      }
                    }}
                  >
                    <i className="icon-settings"></i>
                    {t("menu.settings")}
                  </div>
                  <div className="user-menu__item">
                    <i className="icon-logout"></i>
                    {t("menu.logout")}
                  </div>
                </div>
              </div>
              <div className="buy-info__text">
                {t("page.match.go_to_account")}
              </div>
            </div>
            <div className="buy-info__item">
              <div className="setting-user">
                {!isMobile && (
                  <div className="setting-avatar">
                    <img
                      className="setting-avatar__img"
                      src="/assets/image/user.png"
                      alt="Avatar"
                    />
                    <div className="setting-avatar__name">
                      {auth.isAuth ? auth?.email?.split("@")[0] : "User Name"}
                    </div>
                  </div>
                )}
                <div className="setting-info">
                  <div className="setting-info__data">
                    <div className="setting-info__title">
                      {t("label.telegram")}
                    </div>
                    <div className="setting-info__name">
                      @{auth.isAuth ? auth.telegram : "username"}
                    </div>
                  </div>
                  <div className="setting-info__data">
                    <div className="setting-info__title">
                      {t("label.email")}
                    </div>
                    <div className="setting-info__name">
                      {auth.isAuth ? auth.email : "username@gmail.com"}
                    </div>
                  </div>
                  <img
                    className="setting-info__img"
                    src="/assets/image/phone-in-hand.png"
                    alt="phone-in-hand"
                  />
                </div>
              </div>
              <div className="buy-info__text">
                {t("page.match.check_telegram")}
              </div>
            </div>
          </div>
          <div className="buy-info__row">
            <div className="buy-info__item">
              <div className="balance__wrapper">
                <div className="balance__title">{t("your_balance")}</div>
                <ul className="flex gap-4">
                  {auth.wallets.map((wallet, i) => (
                    <li className="balance__bitcoin" key={i}>
                      <img
                        src={wallet.currency.logo_data_url}
                        style={{ width: "1.5rem" }}
                      />
                      <span>{wallet.balance}</span>
                    </li>
                  ))}
                </ul>
                <button
                  className="btn btn-accent mt-4"
                  disabled={loading}
                  onClick={auth.isAuth ? update : () => { }}
                >
                  {t("update")}
                </button>
                <img
                  className="balance__img"
                  src="/assets/image/wallet.png"
                  alt=""
                />
              </div>
              <div className="buy-info__text">
                {t("page.match.top_up_balance")}
              </div>
            </div>
            <div className="buy-info__item">
              <button className="btn btn-accent" disabled={loading}>
                {t("product_buy")}
              </button>
              <div className="modal buy active relative">
                <div className="modal__content">
                  <div className="backlight backlight-green"></div>
                  <div className="backlight backlight-blue"></div>
                  <ResultModal
                    text={t("buy_success_text", { telegram: auth.telegram })}
                  >
                    <p>
                      {t("buy_success_desc")}{" "}
                      <span
                        className="link"
                        style={{ textTransform: "lowercase" }}
                        onClick={() => {
                          history.push("/contact");
                          modal.destroy();
                        }}
                      >
                        {t("menu.main.contact")}
                      </span>
                    </p>
                  </ResultModal>
                </div>
              </div>
              <div className="buy-info__text">
                {t("page.match.expect_information")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
