import { useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import { useIsMobile } from "../../hooks/isMobile.hook";
import "./style.scss";

export const ContactPage = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Page>
      <main className="main inner">
        <div className="page-title" style={{ textAlign: "center" }}>
          {t("contacts.title")}
        </div>
        <p style={{ textAlign: "center" }}>{t("contacts.subtitle")}</p>
        <ul className="contacts">
          <li className="contacts__item">
            <img src="/assets/image/telegram.png" className="contacts__img" />
            <div className="contacts__info">
              <span className="contacts__name">Telegram</span>
              <a
                className="contacts__value"
                href="https://t.me/collusions_support"
              >
                @collusions_support
              </a>
            </div>
            <a
              className="contacts__value--under"
              href="https://t.me/collusions_support"
            >
              @collusions_support
            </a>
            <div className="backlight backlight-turquoise"></div>
          </li>
          <li className="contacts__item">
            <img src="/assets/image/email.png" className="contacts__img" />
            <div className="contacts__info">
              <span className="contacts__name">Email</span>
              <span className="contacts__value">
                collusions_support@proton.me
              </span>
            </div>
            <span className="contacts__value--under">
              collusions_support@proton.me
            </span>
            <div className="backlight backlight-turquoise"></div>
          </li>
          <li className="contacts__item">
            <img src="/assets/image/jabber.png" className="contacts__img" />
            <div className="contacts__info">
              <span className="contacts__name">Jabber</span>
              <span className="contacts__value">
                collusions.info@thesecure.biz
              </span>
            </div>
            <span className="contacts__value--under">
              collusions.info@thesecure.biz
            </span>
            <div className="backlight backlight-turquoise"></div>
          </li>
          <li className="contacts__item">
            <img src="/assets/image/element.svg" className="contacts__img" />
            <div className="contacts__info">
              <span className="contacts__name">Element</span>
              <span className="contacts__value">
                @collusions.info:matrix.org
              </span>
            </div>
            <span className="contacts__value--under">
              @collusions.info:matrix.org
            </span>
            <div className="backlight backlight-turquoise"></div>
          </li>
        </ul>
        <div className="contact__promo promo-contact">
          <div className="backlight backlight-turquoise promo-contact__decoration-one"></div>
          <div className="backlight backlight-turquoise promo-contact__decoration-two"></div>
          <div className="backlight backlight-turquoise promo-contact__decoration-three"></div>
          <div className="backlight backlight-turquoise promo-contact__decoration-four"></div>
          {!isMobile && (
            <div className="promo-contact__img">
              <img
                src="/assets/image/watch-on-hand.png"
                alt={t("promo_contact.title")}
              />
            </div>
          )}
          <div className="promo-contact__title">{t("promo_contact.title")}</div>
          <div className="promo-contact__text">{t("promo_contact.text")}</div>
        </div>
      </main>
    </Page>
  );
};
