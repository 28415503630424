import { LangItem } from "./LangItem";
import { useTranslation } from "react-i18next";

const langs = [
  ["English", "en"],
  ["Spanish", "es"],
  ["German", "de"],
  ["Portugal", "pt"],
  ["Italian", "it"],
  ["French", "fr"],
];

export const Lang = ({ clickHandler }) => {
  const { i18n } = useTranslation();

  return (
    <div className="lang__list">
      {langs.map(([title, code], i) => (
        <LangItem
          key={i}
          title={title}
          code={code}
          changeLang={i18n.changeLanguage.bind(i18n)}
        />
      ))}
    </div>
  );
};
