import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { MainLayout } from "./components/Layout/MainLayout";
import { Loader } from "./components/UI/Loader/Loader";
import { LoaderWrapper } from "./components/UI/Loader/LoaderWrapper";
import { AuthContext } from "./context/AuthContext";
import { ModalContext } from "./context/ModalContext";
import { NotifiContext } from "./context/NotifiContext";
import { useAuth } from "./hooks/auth.hook";
import { useModal } from "./hooks/modal.hook";
import { useNotifi } from "./hooks/notifi.hook";
import { useRoutes } from "./routes";

function App() {
  const {
    login,
    logout,
    token,
    role,
    balance,
    telegram,
    email,
    wallet,
    ready,
    wallets
  } = useAuth();
  const {
    start,
    destroy,
    reset,
    setChildren,
    setClasses,
    classes,
    active,
    children,
  } = useModal();
  const {
    notifics,
    readNotifics,
    active: notifiActive,
    trigger,
  } = useNotifi(token);
  const isAuth = !!token;
  const routes = useRoutes(isAuth, role);

  if (!ready) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Suspense
      fallback={
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      }
    >
      <ModalContext.Provider
        value={{
          start,
          destroy,
          reset,
          setChildren,
          setClasses,
          classes,
          active,
          children,
        }}
      >
        <AuthContext.Provider
          value={{
            login,
            logout,
            token,
            role,
            balance,
            telegram,
            email,
            wallet,
            isAuth,
            wallets
          }}
        >
          <NotifiContext.Provider
            value={{
              notifics,
              readNotifics,
              active: notifiActive,
              trigger,
            }}
          >
            <BrowserRouter>
              <MainLayout>{routes}</MainLayout>
            </BrowserRouter>
          </NotifiContext.Provider>
        </AuthContext.Provider>
      </ModalContext.Provider>
    </Suspense>
  );
}

export default App;
