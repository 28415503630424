import { useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import { useForm } from "react-hook-form";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useHttp } from "../../hooks/http.hook";
import { useHistory } from "react-router-dom";

export const NewRefLinkPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { t } = useTranslation();
  const { request, error } = useHttp();
  const { token } = useContext(AuthContext);
  const history = useHistory();

  const onSubmit = useCallback(async (data) => {
    try {
      const response = await request("/ref-links", "POST", token, data);
      reset();
      history.push("/ref-links");
    } catch (err) {}
  }, []);

  return (
    <Page>
      <main className="main">
        <div className="page-title">Add new link</div>
        <form onSubmit={handleSubmit(onSubmit)} className="ref-link-form">
          <div className="input-label">
            <label className="input-control">
              <input
                {...register("name", { required: t("errors.input.required") })}
                placeholder="Name"
              />
            </label>
            {errors["name"] ? (
              <div className="input-error">{errors["name"].message}</div>
            ) : null}
          </div>
          <div className="input-label">
            <label className="input-control">
              <div className="prefix">https://collusions.info/ref</div>
              <input {...register("path")} placeholder="URL" />
            </label>
          </div>
          <button className="btn btn-accent">Add</button>
        </form>
      </main>
    </Page>
  );
};
