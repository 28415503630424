import { useEffect, useState, useCallback, useContext } from "react";
import { useHttp } from "../../hooks/http.hook";
import { AuthContext } from "../../context/AuthContext";
import { Page } from "../../components/Layout/Page";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";

export const SingleRefLinkPage = () => {
  const { request, loading } = useHttp();
  const { token } = useContext(AuthContext);
  const [course, setCourse] = useState(0);
  const [stats, setStats] = useState({ metrics: {}, users: [] });
  const { t } = useTranslation();
  const { id } = useParams();

  const getRefStats = useCallback(
    async (id) => {
      try {
        let response = await request(`/ref-analytics/${id}`, "GET", token);
        setStats(response);
      } catch (e) {}
    },
    [request]
  );

  const getCourse = useCallback(async () => {
    try {
      const response = await request("/course");
      setCourse(response.course);
    } catch (e) {}
  }, [request]);

  useEffect(() => getCourse(), [getCourse]);
  useEffect(() => getRefStats(id), [getRefStats, id]);

  return (
    <Page>
      <main className="main">
        <div className="page-title">Statistics for {stats.link?.url}</div>
        <div className="match__body">
          <div className="match__detail">
            <div>
              <div className="match__title">
                {t("referral_stats.verified_users")}
              </div>
              <div className="match__text">{stats.metrics.verified_users}</div>
            </div>
          </div>
          <div className="match__detail">
            <div>
              <div className="match__title">
                {t("referral_stats.unverified_users")}
              </div>
              <div className="match__text">
                {stats.metrics.unverified_users}
              </div>
            </div>
          </div>
          <div className="match__detail">
            <div>
              <div className="match__title">
                {t("referral_stats.order_attempts")}
              </div>
              <div className="match__text">{stats.metrics.order_attempts}</div>
            </div>
          </div>
          <div className="match__detail">
            <div>
              <div className="match__title">
                {t("referral_stats.successful_orders")}
              </div>
              <div className="match__text">
                {stats.metrics.successful_orders}
              </div>
            </div>
          </div>
          <div className="match__detail">
            <div>
              <div className="match__title">
                {t("referral_stats.pageviews")}
              </div>
              <div className="match__text">{stats.metrics.pageviews}</div>
            </div>
          </div>
        </div>
        {stats.users.length ? (
          <section className="overflow-x-auto">
            <table>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Email</td>
                  <td>Telegram</td>
                  <td>Order attempts</td>
                  <td>Successful orders</td>
                  <td>Total debit</td>
                  <td>Total credit</td>
                </tr>
              </thead>
              <tbody>
                {stats.users.map((u, idx) => (
                  <tr key={idx}>
                    <td>{u.user.id}</td>
                    <td>{u.user.email}</td>
                    <td>{u.user.telegram}</td>
                    <td>{u.order_attempts}</td>
                    <td>{u.successful_orders}</td>
                    <td>
                      {u.debit_amount / process.env.REACT_APP_SATOSHI_CONVERT}{" "}
                      BTC
                    </td>
                    <td>
                      {u.credit_amount / process.env.REACT_APP_SATOSHI_CONVERT}{" "}
                      BTC
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        ) : null}
      </main>
    </Page>
  );
};
