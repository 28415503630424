import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { CourseAdmin } from "./pages/Admin/CourseAdmin";
import { MatchAdmin } from "./pages/Admin/MatchAdmin";
import { OrderAdmin } from "./pages/Admin/OrderAdmin";
import { ReportsAdmin } from "./pages/Admin/ReportsAdmin";
import { UserAdmin } from "./pages/Admin/UserAdmin";
import { BalancePage } from "./pages/BalancePage/BalancePage";
// import { BlogPage } from "./pages/BlogPage/BlogPage"
// import { BlogPostPage } from "./pages/BlogPage/BlogPostPage"
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { FaqPage } from "./pages/FaqPage/FaqPage";
import { MainPage } from "./pages/MainPage/MainPage";
import { MatchPage } from "./pages/MatchPage/MatchPage";
import { PartneringPage } from "./pages/PartneringPage/PartneringPage";
import { ReferralPage } from "./pages/ReferralPage/ReferralPage";
import { ReportPage } from "./pages/ReportsPage/ReportPage";
import { ReportsPage } from "./pages/ReportsPage/ReportsPage";
import { RulesPage } from "./pages/RulesPage/RulesPage";
import { SettingPage } from "./pages/SettingPage/SettingPage";
import { StatisticPage } from "./pages/StatisticPage/StatisticPage";
import { RefLinksPage } from "./pages/RefLinksPage/RefLinksPage";
import { NewRefLinkPage } from "./pages/RefLinksPage/NewRefLinkPage";
import { SingleRefLinkPage } from "./pages/RefLinksPage/SingleRefLinkPage";
import { BOAccountPage } from "./pages/BOAccountPage/BOAccountPage";
import { SingleBOAccountPage } from "./pages/BOAccountPage/SingleBOAccountPage";

export const useRoutes = (isAuth, role) => {
  const routes = [
    { component: ContactPage, path: "/contact", exact: true },
    { component: MainPage, path: "/", exact: true },
    { component: MainPage, path: "/ref/:ref", exact: false },
    { component: MainPage, path: "/verify/:email/:hash", exact: false },
    { component: MainPage, path: "/auth/telegram", exact: true },
    { component: MainPage, path: "/user/:id/emails", exact: true },
    { component: MatchPage, path: "/match", exact: true },
    { component: PartneringPage, path: "/partnering", exact: true },
    { component: ReferralPage, path: "/referral", exact: true },
    { component: RefLinksPage, path: "/ref-links", exact: true },
    { component: BOAccountPage, path: "/bo-accounts", exact: true },
    { component: NewRefLinkPage, path: "/ref-links/new", exact: true },
    { component: SingleRefLinkPage, path: "/ref-links/:id", exact: true },
    { component: RulesPage, path: "/rules", exact: true },
    { component: StatisticPage, path: "/statistic", exact: true },
    { component: ReportsPage, path: "/reports", exact: true },
    { component: ReportPage, path: "/reports/:id", exact: false },
    // {component: BlogPage, path: '/blog', exact: true},
    // {component: BlogPostPage, path: '/blog/:id', exact: false},
    { component: FaqPage, path: "/faq", exact: true },
    { component: FaqPage, path: "/faq/:key/:id", exact: false },
  ];

  if (isAuth) {
    routes.push({ component: BalancePage, path: "/balance" });
    routes.push({ component: SettingPage, path: "/setting", exact: true });
  }

  if (isAuth && role === process.env.REACT_APP_ROLE_ADMIN) {
    routes.push({ component: MatchAdmin, path: "/admin/match", exact: true });
    routes.push({
      component: MatchAdmin,
      path: "/admin/match/:id",
      exact: false,
    });
    routes.push({ component: UserAdmin, path: "/admin/user", exact: true });
    routes.push({ component: OrderAdmin, path: "/admin/order", exact: true });
    routes.push({ component: CourseAdmin, path: "/admin/course", exact: true });
    routes.push({
      component: ReportsAdmin,
      path: "/admin/reports",
      exact: true,
    });
    routes.push({
      component: SingleBOAccountPage,
      path: "/bo-accounts/new",
      exact: true,
    });
    routes.push({
      component: SingleBOAccountPage,
      path: "/bo-accounts/:id",
      exact: true,
    });
  }

  return (
    <Switch>
      {routes.map((route) => (
        <Route {...route} key={route.path} />
      ))}
      <Redirect to="/" />
    </Switch>
  );
};
