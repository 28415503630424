import { useState, useEffect, useContext } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Header } from "../Header";
import { Menu } from "../Menu/Menu";
import { Modal } from "../UI/Modal/Modal";
import { useHttp } from "../../hooks/http.hook";
import { AuthContext } from "../../context/AuthContext";
import { pageView } from "../../utils";
import { useTranslation } from "react-i18next";

export const MainLayout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotifiOpen, setIsNotifiOpen] = useState(false);
  const { request } = useHttp();
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const match = useRouteMatch("/ref/:ref");
  const { i18n } = useTranslation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const lang = query.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [location.search, i18n])

  useEffect(() => {
    pageView();
    request("/pageviews", "POST", token, {
      href: window.location.href,
      title: document.title,
    });
  }, [location]);

  useEffect(() => {
    if (match) {
      window.sessionStorage.setItem("ref", match.params.ref);
    }
  }, [match]);

  return (
    <>
      <Header
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isNotifiOpen={isNotifiOpen}
        setIsNotifiOpen={setIsNotifiOpen}
      />
      <div
        className={isMenuOpen ? "page__container active" : "page__container"}
        onClick={() => setIsMenuOpen(false)}
      >
        <Menu
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          setIsNotifiOpen={setIsNotifiOpen}
        />
        {children}
      </div>
      <Modal />
    </>
  );
};
