import { useCallback, useContext, useEffect, useState } from "react";
import { QueueMatchForm } from "../../components/QueueMatchForm";
import { MatchForm } from "../../components/MatchForm";
import { AuthContext } from "../../context/AuthContext";
import { useHistory, useParams } from "react-router";
import { useHttp } from "../../hooks/http.hook";
import { Page } from "../../components/Layout/Page";
import { HistoryMatchForm } from "../../components/HistoryMatchForm";

export const MatchAdmin = () => {
  const [match, setMatch] = useState({});
  const [trigger, setTrigger] = useState(0);
  const { id } = useParams();
  const { request } = useHttp();
  const history = useHistory();

  const { token } = useContext(AuthContext);

  const save = async (data, request, reset) => {
    try {
      await request("/match", "POST", token, data);
      setTrigger((prev) => prev + 1);
      reset();
    } catch (e) {}
  };

  const update = async (data, request, reset) => {
    try {
      await request(`/match/${id}`, "PUT", token, data);
      reset();
      history.push("/admin/match");
    } catch (e) {}
  };

  const getMatch = useCallback(async () => {
    try {
      const response = await request(`/match/${id}`, "GET", token);
      if (!response) {
        return history.push("/admin/match");
      }
      setMatch(response);
    } catch (e) {
      history.push("/admin/match");
    }
  }, [request, id, token, history]);

  useEffect(() => id && getMatch(), [id, getMatch]);

  return (
    <Page>
      <main className="main">
        <div className="page-title">{id ? "Editing a match" : "Match"}</div>
        <MatchForm match={match} onSubmit={id ? update : save} />
        {!id && (
          <>
            <div className="page-title">Match queue</div>
            <QueueMatchForm trigger={trigger} />
            <div className="page-title">Match closed</div>
            <HistoryMatchForm trigger={trigger} />
          </>
        )}
      </main>
    </Page>
  );
};
