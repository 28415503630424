import { createContext } from "react";

export const AuthContext = createContext({
  token: null,
  role: null,
  balance: 0,
  telegram: null,
  email: null,
  wallet: null,
  login: () => {},
  logout: () => {},
  isAuth: false,
  wallets: [],
});
