import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AuthContext } from "../../../context/AuthContext";
import { ModalContext } from "../../../context/ModalContext";
import { useIsMobile } from "../../../hooks/isMobile.hook";
import { RegisterModal } from "../../Modal/RegisterModal";

export const Promo = () => {
  const auth = useContext(AuthContext);
  const modal = useContext(ModalContext);
  const history = useHistory();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <div className="promo">
      <div className="backlight backlight-blue"></div>
      <img
        className="promo__img"
        src={
          "/assets/image/money-in-hand-" +
          (isMobile ? "mobile" : "desctop") +
          ".png"
        }
        alt="Money in hand"
      />
      <h1 className="promo__title">{t("page.main.promo_title")}</h1>
      <p className="promo__text">{t("page.main.promo_text")}</p>
      <button
        className="btn btn-accent"
        onClick={() => {
          if (auth.isAuth) {
            return history.push("/match");
          }

          modal.setClasses(["login"]);
          modal.setChildren(<RegisterModal />);
          modal.start();
        }}
      >
        {t("page.main.promo_btn")}
      </button>
    </div>
  );
};
