import { useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import { useCallback, useEffect, useState, useContext } from "react";
import { useHttp } from "../../hooks/http.hook";
import { useHistory, useParams } from "react-router-dom";
import { BOAccountForm } from "../../components/BOAccountForm";
import "./styles.scss";
import { AuthContext } from "../../context/AuthContext";

export const SingleBOAccountPage = () => {
  const [account, setAccount] = useState();
  const { t } = useTranslation();
  const { token } = useContext(AuthContext);
  const { request } = useHttp();
  const history = useHistory();
  const { id } = useParams();

  const getAccount = useCallback(
    async (id) => {
      try {
        const account = await request(`/bo-accounts/${id}`, "GET", token);
        setAccount(account);
      } catch (e) {}
    },
    [request]
  );

  useEffect(() => id && getAccount(id), [id, getAccount]);

  return (
    <Page>
      <main className="main">
        <div className="page-title">
          {id ? "Editing account" : "Add new account"}
        </div>
        <BOAccountForm
          account={account}
          onDone={() => history.push("/bo-accounts")}
        />
      </main>
    </Page>
  );
};
