import { useState, useCallback, useEffect, useContext } from "react";
import { Page } from "../../components/Layout/Page";
import { useHttp } from "../../hooks/http.hook";
import { Link, useRouteMatch } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./styles.scss";

export const BOAccountPage = () => {
  const [accounts, setAccounts] = useState([]);
  const { request } = useHttp();
  const { url } = useRouteMatch();
  const auth = useContext(AuthContext);

  const getAccounts = useCallback(async () => {
    try {
      const response = await request("/bo-accounts");
      setAccounts(response);
    } catch (e) {}
  }, [request]);

  useEffect(() => getAccounts(), [getAccounts]);

  return (
    <Page>
      <main className="main">
        <div className="top">
          <h1 className="page-title">BO Accounts</h1>
          {auth.role === "ADMIN" ? (
            <Link to={`${url}/new`} className="btn btn-accent">
              Add
            </Link>
          ) : null}
        </div>
        <section className="overflow-x-auto">
          <table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Country</td>
                <td>Comission for refill</td>
                <td>Withdrawal fee</td>
                <td>Price</td>
                {auth.role === "ADMIN" ? <td>Actions</td> : null}
                <td style={{ width: "1%" }}>Availability</td>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account, idx) => (
                <tr key={idx} className="bo-account">
                  <td>{account.name}</td>
                  <td>
                    <img
                      className="lang-icon"
                      src={
                        process.env.REACT_APP_API_HOST +
                        "/flags/" +
                        account.country.iso +
                        ".png"
                      }
                      alt={`Flag of ${account.country.name}`}
                      title={account.country.name}
                    />
                  </td>
                  <td>
                    <div className="bo-account__badge">
                      +{account.comission}%
                    </div>
                  </td>
                  <td>
                    <div className="bo-account__badge">
                      +{account.withdrawal_fee}%
                    </div>
                  </td>
                  <td>${account.price}</td>
                  {auth.role === "ADMIN" ? (
                    <td>
                      <Link
                        to={`/bo-accounts/${account.id}`}
                        className="btn btn-edit"
                      >
                        Edit
                      </Link>
                    </td>
                  ) : null}
                  <td>
                    {account.available ? (
                      <Link to="/contact" className="btn btn-accent">
                        Contact us
                      </Link>
                    ) : (
                      <button className="btn btn-secondary" disabled={true}>
                        Unavailable
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </Page>
  );
};
