import { useContext, useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import { ModalContext } from "../context/ModalContext";
import { useHttp } from "../hooks/http.hook";
import { ResultModal } from "./Modal/ResultModal";
import { InputLabel } from "./UI/InputLabel";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { trackCustom } from "../utils";

export const RegisterForm = ({ method, setMethod }) => {
  const modal = useContext(ModalContext);
  const history = useHistory();
  const { error, loading, request, clearError } = useHttp();
  const { t } = useTranslation();
  const ref = useRef(window.sessionStorage.getItem("ref"));

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    clearErrors,
    formState: { errors, dirtyFields },
    control,
  } = useForm();

  useEffect(() => {
    if (!error) return;
    switch (error) {
      case "errors.user.registration.email_busy":
        setError("email", { type: "manual", message: t(error) });
        break;
      case "errors.user.registration.telegram_busy":
        setError("telegram", { type: "manual", message: t(error) });
        break;
      default:
        setError("repeatPassword", { type: "manual", message: t(error) });
        break;
    }
    clearError();
  }, [error]);

  const onSubmit = async (data) => {
    try {
      await request("/user/registration", "POST", null, {
        email: data.email,
        password: data.password,
        telegram: data.telegram,
        ref: window.sessionStorage.getItem("ref"),
      });

      modal.setClasses(["success"]);
      modal.setChildren(
        <ResultModal text={t("activation_letter")}>
          <button
            className="btn btn-accent"
            onClick={() => {
              modal.destroy();
              history.push("/contact");
            }}
          >
            {t("menu.main.contact")}
          </button>
        </ResultModal>
      );
      modal.start();
    } catch (e) { }
  };

  const fields = [
    {
      name: "email",
      icon: "email-circle",
      input: {
        type: "text",
        placeholder: t("placeholder.your_email"),
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
        pattern: {
          value: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
          message: t("errors.input.email"),
        },
      },
    },
    {
      name: "telegram",
      icon: "telegram-circle-blue",
      input: {
        type: "text",
        placeholder: t("placeholder.your_telegram"),
        disabled: loading,
      },
      validate: { required: t("errors.input.required") },
    },
    {
      name: "password",
      icon: "key",
      input: {
        type: "password",
        placeholder: t("placeholder.password"),
        disabled: loading,
      },
      validate: { required: t("errors.input.required") },
      callable: {
        onKeyUp: () => {
          if (getValues("password") === getValues("repeatPassword")) {
            clearErrors("repeatPassword");
          }
        },
      },
    },
    {
      name: "repeatPassword",
      icon: "key",
      input: {
        type: "password",
        placeholder: t("placeholder.repeat_password"),
        disabled: loading,
      },
      validate: { required: t("errors.input.required") },
      callable: {
        onKeyUp: () => {
          if (
            getValues("password") !== getValues("repeatPassword") &&
            !errors.repeatPassword
          ) {
            return setError("repeatPassword", {
              type: "manual",
              message: t("errors.input.no_repeat_password"),
            });
          }

          if (getValues("password") === getValues("repeatPassword")) {
            return clearErrors("repeatPassword");
          }
        },
      },
    },
  ];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center"
    >
      {method === "telegram" ? (
        <a
          href={ref.current ? `${process.env.REACT_APP_TG_BOT_URL}?start=ref_${ref.current}` : process.env.REACT_APP_TG_BOT_URL}
          className="btn btn-accent telegram-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="mr-2"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
              <path
                fill="currentColor"
                d="M19.777 4.43a1.5 1.5 0 0 1 2.062 1.626l-2.268 13.757c-.22 1.327-1.676 2.088-2.893 1.427c-1.018-.553-2.53-1.405-3.89-2.294c-.68-.445-2.763-1.87-2.507-2.884c.22-.867 3.72-4.125 5.72-6.062c.785-.761.427-1.2-.5-.5c-2.303 1.738-5.998 4.381-7.22 5.125c-1.078.656-1.64.768-2.312.656c-1.226-.204-2.363-.52-3.291-.905c-1.254-.52-1.193-2.244-.001-2.746l17.1-7.2Z"
              />
            </g>
          </svg>
          {t("continue_with_telegram")}
        </a>
      ) : (
        fields.map((field) => (
          <InputLabel
            icon={field.icon}
            key={field.name}
            setting={{
              ...field.input,
              ...register(field.name, field.validate),
              ...field.callable,
            }}
            error={dirtyFields[field.name] && errors[field.name] && errors[field.name].message}
          />
        ))
      )}
      <div className="checkbox-custom mt-4">
        <Controller
          name="agreed"
          defaultValue={true}
          control={control}
          rules={{
            validate: (v) => v || t("errors.input.agree_rules")
          }}
          render={({ field }) => (
            <input
              {...field}
              className="checkbox-custom__input"
              type="checkbox"
              id="agreed"
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
        <label className="checkbox-custom__label" htmlFor="agreed">
          I agree to the&nbsp;
          <Link to="/rules" className="link-blue">
            rules
          </Link>
        </label>
      </div>
      {dirtyFields["agreed"] && errors["agreed"] ? (
        <div
          className="input-error"
          style={{ margin: "10px auto", width: "max-content" }}
        >
          {errors["agreed"].message}
        </div>
      ) : null}
    </form>
  );
};
