export function fbq(...params) {
  if (!window.fbq) return
  window.fbq(...params);
}

export function pageView() {
  fbq("track", "PageView");
}

export function track(...params) {
  fbq("track", ...params);
}

export function trackCustom(...params) {
  fbq("trackCustom", ...params);
}

