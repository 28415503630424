import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

export const Chart = ({ percent, innerRadius = 55, outerRadius = 95 }) => {
  const data = [
    { name: "Winned", value: percent.win || 0 },
    { name: "loosed", value: percent.lose || 0 },
  ];

  const COLORS = ["url(#colorWinned)", "url(#colorLoosed)"];

  return (
    <ResponsiveContainer width={outerRadius * 2} height={outerRadius * 2}>
      <PieChart>
        <defs>
          <linearGradient
            id="colorLoosed"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#BA4545" />
            <stop offset="1" stopColor="#BB0303" />
          </linearGradient>
          <linearGradient
            id="colorWinned"
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            spreadMethod="reflect"
          >
            <stop offset="0" stopColor="#5CD098" />
            <stop offset="1" stopColor="#159A7A" />
          </linearGradient>
        </defs>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          dataKey="value"
          animationDuration={3000}
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
