import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";
import { AuthContext } from "../../context/AuthContext";
import { useHttp } from "../../hooks/http.hook";

export const CourseAdmin = () => {
  const [course, setCourse] = useState(0);
  const [newCourse, setNewCourse] = useState("");
  const { request, loading } = useHttp();
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();

  const getExchangeRate = useCallback(async () => {
    try {
      const response = await request("/exchange-rates/BTC/USD?value=1", "GET");
      setCourse(response.result);
    } catch(e) {
    }
  }, [request]);

  useEffect(() => getExchangeRate(), [getExchangeRate]);

  const update = async (e) => {
    e.preventDefault();
    if (!newCourse) return;
    try {
      await request("/exchange-rates/BTC?targets=USD&targets=USDT", "PATCH", token, {  rate: newCourse });
    } catch (e) {}
  };

  return (
    <Page>
      <main className="main inner">
        <div className="page-title">{t("page.course.title")}</div>
        <p>
          {t("page.course.current", {
            course: new Intl.NumberFormat("ru-RU").format(course),
          })}
        </p>
        <form>
          <div className="input-label">
            <div className="input-control">
              <input
                type="number"
                placeholder={t("placeholder.new_course")}
                value={newCourse}
                onChange={(e) => setNewCourse(e.target.value)}
                disabled={loading}
              />
            </div>
          </div>
          <button
            className="btn btn-secondary"
            onClick={update}
            disabled={loading}
          >
            {t("update")}
          </button>
        </form>
      </main>
    </Page>
  );
};
