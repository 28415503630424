import "./style.scss";
import { useTranslation } from "react-i18next";
import { Page } from "../../components/Layout/Page";

export const RulesPage = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <main className="main inner">
        <div className="page-title">{t("rules.title")}</div>
        <ul className="rules__list">
          {t("rules.list", { returnObjects: true }).map((rule) => (
            <li className="rules__item">
              <div className="rules__item-title">{rule.title}</div>
              <ul>
                {rule.list.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </main>
    </Page>
  );
};
