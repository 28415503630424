import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AuthContext } from "../../../context/AuthContext";
import { ModalContext } from "../../../context/ModalContext";
import { useHttp } from "../../../hooks/http.hook";
import { RegisterModal } from "../../Modal/RegisterModal";
import { LoaderMini } from "../../UI/Loader/LoaderMini";

export const Product = () => {
  const auth = useContext(AuthContext);
  const modal = useContext(ModalContext);

  const { t } = useTranslation();

  const { request, loading } = useHttp();
  const [match, setMatch] = useState(null);

  const history = useHistory();

  const getMatch = useCallback(async () => {
    try {
      const response = await request("/match", "GET");
      setMatch(response);
    } catch (e) {}
  }, [request]);

  const getDay = (date) => new Date(date).getDate();
  const getMonth = (date) => new Date(date).getMonth() + 1;

  useEffect(() => getMatch(), [getMatch]);

  return (
    <div className="product">
      <div className="backlight backlight-blue"></div>
      <h2 className="product__title">{t("page.main.product_title")}</h2>
      <ul className="product__info info-product">
        <li className="info-product__item">
          <span className="info-product__title">{t("country")}</span>
          <div className="info-product__country">
            <span className="info-product__text">
              {loading || !match ? <LoaderMini /> : match.country.name}
            </span>
            {match?.country && (
              <img
                src={
                  process.env.REACT_APP_API_HOST +
                  "/flags/" +
                  match.country.iso +
                  ".png"
                }
                alt={match.country.iso}
              />
            )}
          </div>
        </li>
        <li className="info-product__item">
          <span className="info-product__title">{t("league")}</span>
          <span className="info-product__text">
            {loading || !match ? <LoaderMini /> : match?.league}
          </span>
        </li>
        <li className="info-product__item">
          <span className="info-product__title">{t("odds")}</span>
          <span className="info-product__text">
            {loading || !match ? <LoaderMini /> : match?.odds}
          </span>
        </li>
        <li className="info-product__item">
          <span className="info-product__title">{t("date")}</span>
          <span className="info-product__text">
            {loading || !match ? (
              <LoaderMini />
            ) : (
              `${
                t("months", { returnObjects: true })[getMonth(match.date) - 1]
              } ${getDay(match.date)}`
            )}
          </span>
        </li>
        <li className="info-product__item">
          <span className="info-product__title">{t("time")}</span>
          <span className="info-product__text">
            {loading || !match ? (
              <LoaderMini />
            ) : (
              t("until_gtm", {
                time: match?.time
                  ? match.time.substring(0, match.time.lastIndexOf(":"))
                  : "-",
              })
            )}
          </span>
        </li>
      </ul>
      <div className="info-product__footer">
        <div className="info-product__price">
          <span>{t("price")}</span>
          <span>{loading || !match ? <LoaderMini /> : "$" + match.price}</span>
        </div>
        <button
          className="btn btn-accent"
          onClick={() => {
            if (auth.isAuth) {
              return history.push("/match");
            }

            modal.setClasses(["login"]);
            modal.setChildren(<RegisterModal />);
            modal.start();
          }}
        >
          {t("product_buy")}
        </button>
      </div>
    </div>
  );
};
