import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

export const StatisticPercent = ({ win, lose }) => {
  const { t } = useTranslation();
  return (
    <div className="statistic__matches">
      <div className="statistic__matches-win">
        <CountUp end={win} duration={3} suffix="%" preserveValue={true} />
        <span>{t("winned")}</span>
      </div>
      <div className="statistic__matches-lose">
        <CountUp end={lose} duration={3} suffix="%" preserveValue={true} />
        <span>{t("loosed")}</span>
      </div>
    </div>
  );
};
