import { useContext, useCallback } from "react";
import { ModalContext } from "../../context/ModalContext";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../hooks/http.hook";

export const EmailConfirmationModal = ({ data, url }) => {
  const { t, i18n } = useTranslation();
  const modal = useContext(ModalContext);
  const { request } = useHttp();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      await request(url, "POST");
      modal.destroy();
    } catch(e) {}
  }, [url])

  return (
    <>
      <h2 className="modal__title">{data.old_email ? t("update_email.title") : t("new_email.title")}</h2>
      <p>{data.old_email ? t("update_email.description", data)  : t("new_email.description")}</p>
      <form onSubmit={onSubmit} method="post">
        <button className="btn btn-accent">{data.old_email ? t("update_email.update") : t("new_email.add")}</button>
      </form>
    </>
  );
};
