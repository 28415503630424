import { useHistory } from "react-router";
import { useIsMobile } from "../hooks/isMobile.hook";
import { useShuffle } from "../hooks/shuffle.hook";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHttp } from "../hooks/http.hook";
import { Chart } from "./Chart";
import { AuthContext } from "../context/AuthContext";
import { StatisticPercent } from "./StatisticPercent";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

export const StaticticMainPage = () => {
  const isMobile = useIsMobile();
  const { shuffle } = useShuffle();
  const { request } = useHttp();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const limit = isMobile ? 5 : 7;

  const [matches, setMatches] = useState([]);
  const [percent, setPercent] = useState({ win: 0, lose: 0 });

  const getDay = (date) => {
    let day = new Date(date).getDate();
    if (day < 10) {
      day = "0" + day;
    }
    return day;
  };

  const getMonth = (date) => {
    let month = new Date(date).getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    return month;
  };

  const getStatistics = useCallback(async () => {
    try {
      const response = await request(`/match/closed?limit=${limit}`);
      setMatches(response.matches);
      const count = 100 / (response.win + response.lose);

      setPercent({
        win: response.win * count,
        lose: response.lose * count,
      });
    } catch (e) {}
  }, []);

  useEffect(() => getStatistics(), [getStatistics]);

  const getTable = (match) => {
    return (
      <div className="table_row" key={match.id}>
        <div className="table_small">
          <div className="table_cell">{t("date")}</div>
          <div className="table_cell">
            {getDay(match.date) + "." + getMonth(match.date)}
          </div>
        </div>
        <div className="table_small">
          <div className="table_cell">{t("country")}</div>
          <div className="table_cell">
            <img
              className="lang-icon"
              src={
                process.env.REACT_APP_API_HOST +
                "/flags/" +
                match.country.iso +
                ".png"
              }
              alt="Флаг"
              title="Страна"
            />
          </div>
        </div>
        <div className="table_small">
          <div className="table_cell">{t("teams")}</div>
          <div className="table_cell teams">
            {auth.isAuth ? (
              match.teams
            ) : (
              <>
                <span className="blur">{shuffle(match.teams)}</span>
                <span className="no_visible">{t("register_first")}</span>
              </>
            )}
          </div>
        </div>
        <div className="table_small">
          <div className="table_cell">{t("info")}</div>
          <div className="table_cell">
            {auth.isAuth ? (
              match.info
            ) : (
              <>
                <span className="blur">{shuffle(match.info)}</span>
                <span className="no_visible">{t("register_first")}</span>
              </>
            )}
          </div>
        </div>
        <div className="table_small">
          <div className="table_cell">{t("odds")}</div>
          <div className="table_cell">{match.odds}</div>
        </div>
        <div className="table_small">
          <div className="table_cell">
            {t("win")}/{t("lose")}
          </div>
          <div className="table_cell result">
            {match.result ? (
              <>
                <i className="icon-win"></i>
                <span className="win">{t("win")}</span>
              </>
            ) : (
              <>
                <i className="icon-lose"></i>
                <span className="lose">{t("lose")}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="statistic"
      onClick={() => {
        // history.push('/statistic')
      }}
    >
      <div className="statistic__inner">
        <h2 className="statistic__title">{t("statistics")}</h2>
        {isMobile && <StatisticPercent win={percent.win} lose={percent.lose} />}
        <div className="statistic__chart">
          <Chart percent={percent} />
        </div>
        <h2 className="statistic__matches-title">{t("matches")}</h2>
        {!isMobile && (
          <StatisticPercent win={percent.win} lose={percent.lose} />
        )}
        {isMobile && (
          <div className="statistic__label">
            <span className="win">
              <span></span>
              {t("win")}
            </span>
            <span className="lose">
              <span></span>
              {t("lose")}
            </span>
          </div>
        )}
      </div>
      <div className="statistic__table">
        <div className="table">
          <div className="theader">
            <div className="table_header">{t("date")}</div>
            <div className="table_header">{t("country")}</div>
            <div className="table_header">{t("teams")}</div>
            <div className="table_header">{t("info")}</div>
            <div className="table_header">{t("odds")}</div>
            <div className="table_header">
              {t("win")}/{t("lose")}
            </div>
          </div>
          {isMobile ? (
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              grabCursor={true}
              breakpoints={{
                650: {
                  spaceBetween: 20,
                },
              }}
            >
              {matches.map((match) => (
                <SwiperSlide key={match.id}>{getTable(match)}</SwiperSlide>
              ))}
              <SwiperSlide>
                <div className="slider__cap">
                  <button
                    className="btn btn-accent"
                    onClick={() => history.push("/statistic")}
                  >
                    {t("see_more")}
                  </button>
                  <img
                    src="/assets/image/vertical-phone-in-hand.png"
                    alt="Vertical phone in hand"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          ) : (
            matches.map((match) => getTable(match))
          )}
        </div>
      </div>
    </div>
  );
};
