import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CardItem } from "./CardItem";
// import 'swiper/swiper-bundle.min.css'
import "swiper/swiper.min.css";
import { useCallback, useEffect, useState } from "react";
import { useHttp } from "../hooks/http.hook";

export const ReportsLasts = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [reports, setReports] = useState([]);
  const { request } = useHttp();

  const getDay = (date) => new Date(date).getDate();
  const getMonth = (date) => new Date(date).getMonth();
  const getYear = (date) => new Date(date).getFullYear();

  const getReports = useCallback(async () => {
    try {
      const response = await request(`/reports?limit=3&page=1`);
      setReports((prev) => response);
    } catch (e) {}
  }, [request]);

  useEffect(() => {
    getReports();
  }, [getReports]);

  if (!reports.length) {
    return <></>;
  }

  return (
    <div className="reports-main">
      <h2 className="reports-main__title">{t("page.main.reports")}</h2>
      <div>
        <Swiper
          spaceBetween={13}
          slidesPerView={1}
          grabCursor={true}
          breakpoints={{
            650: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1100: {
              slidesPerView: 3,
            },
          }}
        >
          {reports.map((report) => (
            <SwiperSlide key={report.id}>
              <div
                className="report__item"
                onClick={() => {
                  history.push(`/reports/${report.id}`);
                }}
              >
                <div className="report__name">{report.name}</div>
                <div className="report__date">
                  {t("months", { returnObjects: true })[getMonth(report.date)] +
                    " " +
                    getDay(report.date) +
                    ", " +
                    getYear(report.date)}
                </div>
                <div className="report__images">
                  <div className="report__image">
                    <img
                      src={
                        process.env.REACT_APP_API_HOST + report.command_1_logo
                      }
                      alt=""
                    />
                  </div>
                  <div className="report__vs">vs</div>
                  <div className="report__image">
                    <img
                      src={
                        process.env.REACT_APP_API_HOST + report.command_2_logo
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="report__commands">{report.commands}</div>
              </div>
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <div className="slider__cap">
              <button
                className="btn btn-accent"
                onClick={() => history.push("/reports")}
              >
                {t("see_more")}
              </button>
              <img
                src="/assets/image/vertical-phone-in-hand.png"
                alt="Vertical phone in hand"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
