import { useState, useEffect, useCallback, useContext } from "react";
import { Page } from "../../components/Layout/Page";
import { MessageForm } from "../../components/MessageForm";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { useHttp } from "../../hooks/http.hook";
export const UserAdmin = () => {
  const [users, setUsers] = useState([]);
  const { request } = useHttp();
  const { token } = useContext(AuthContext);
  const modal = useContext(ModalContext);

  const getUsers = useCallback(async () => {
    try {
      const response = await request("/user", "GET", token);
      setUsers(response);
    } catch (e) { }
  }, [request, token]);

  useEffect(() => getUsers(), [getUsers]);

  return (
    <Page>
      <main className="main">
        <div className="page-title">Users</div>
        <section className="overflow-x-auto">
          <table>
            <thead>
              <tr>
                <td>#</td>
                <td>E-mail</td>
                <td>Telegram</td>
                <td>Balance</td>
                <td>Message</td>
                <td>Created at</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {users.map((user, i) => (
                <tr key={user.id}>
                  <td>{i + 1}</td>
                  <td>
                    <a href={`mailto://${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {user.telegram ? (
                      <a
                        href={`https://t.me/${user.telegram}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {user.telegram}
                      </a>
                    ) : (
                      <span>{user.telegram_id}</span>
                    )}
                  </td>
                  <td>
                    {user.balance / process.env.REACT_APP_SATOSHI_CONVERT}
                  </td>
                  <td>
                    <span
                      className="link"
                      onClick={() => {
                        modal.setChildren(
                          <MessageForm
                            title={`Message for @${user.telegram}`}
                            userId={user.id}
                            beforeFunc={() => {
                              modal.destroy();
                            }}
                          />
                        );
                        modal.start();
                      }}
                    >
                      Write message
                    </span>
                  </td>
                  <td>{new Date(user.createdAt).toLocaleString("ru")}</td>
                  <td>{user.status && "active"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
    </Page>
  );
};
