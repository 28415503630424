import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";
import { useHttp } from "../hooks/http.hook";
import { ControlledInputs } from "./ControlledInputs";
import { SettingInputs } from "./SettingInputs";

export const MatchForm = ({ onSubmit, match = {} }) => {
  const [countries, setCountries] = useState([]);
  const { request, loading } = useHttp();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const getCountries = useCallback(async () => {
    try {
      setCountries(await request("/country", "GET", auth.token));
    } catch (e) {}
  }, [request, auth]);

  useEffect(() => getCountries(), [getCountries]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (match?.country?.id) {
      setValue("country", match?.country?.id || "");
    }
    setValue("league", match?.league || "");
    setValue("odds", match?.odds || "");
    setValue("date", match?.date || "");
    setValue("time", match?.time || "");
    setValue("price", match?.price || "");
    setValue("teams", match?.teams || "");
    setValue("info", match?.info || "");
  }, [match]);

  const fields = [
    {
      name: "league",
      icon: "",
      input: {
        type: "text",
        placeholder: "League",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "odds",
      icon: "",
      input: {
        type: "text",
        placeholder: "Odds",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "date",
      icon: "",
      input: {
        type: "date",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "time",
      icon: "",
      input: {
        type: "time",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "price",
      icon: "",
      input: {
        type: "number",
        placeholder: "Price in dollar",
        step: "0.01",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "teams",
      icon: "",
      input: {
        type: "text",
        placeholder: "Teams",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
    {
      name: "info",
      icon: "",
      input: {
        type: "text",
        placeholder: "Info",
        disabled: loading,
      },
      validate: {
        required: t("errors.input.required"),
      },
    },
  ];

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data, request, reset);
      })}
    >
      <SettingInputs>
        <div className="input-label">
          <select
            {...register("country", { required: t("errors.input.required") })}
            disabled={loading}
          >
            <option value="">Сhoose a country</option>
            {countries.map((country) => (
              <option value={country.id} key={country.id}>
                {country.name}
              </option>
            ))}
          </select>
          {errors.country && (
            <div className="input-error">{errors.country.message}</div>
          )}
        </div>
      </SettingInputs>
      <SettingInputs>
        <ControlledInputs fields={fields} register={register} errors={errors} />
      </SettingInputs>
      <button className="btn btn-accent">Save</button>
    </form>
  );
};
