import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { Lang } from "../Lang/Lang";
import { useTranslation } from "react-i18next";

export const LangModal = () => {
  const { t } = useTranslation();
  const modal = useContext(ModalContext);

  return (
    <div className="flex flex-col items-center">
      <h2 className="modal__title">{t("choose_language")}</h2>
      <Lang />
      <button className="btn btn-accent" onClick={modal.destroy}>
        {t("continue")}
      </button>
    </div>
  );
};
