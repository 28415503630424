import { InputLabel } from "./UI/InputLabel";

export const ControlledInputs = ({ fields, register, errors }) => {
  return (
    <>
      {fields.map((field) => (
        <InputLabel
          icon={field.icon}
          key={field.name}
          setting={{
            ...field.input,
            ...register(field.name, field.validate),
            ...field.callable,
          }}
          error={errors[field.name] && errors[field.name].message}
        />
      ))}
    </>
  );
};
