import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIsMobile } from "../hooks/isMobile.hook";
import { ModalContext } from "../context/ModalContext";
import { LoginModal } from "./Modal/LoginModal";
import { RegisterModal } from "./Modal/RegisterModal";
import { LangModal } from "./Modal/LangModal";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { NotifiContext } from "../context/NotifiContext";

export const Header = ({
  isMenuOpen,
  setIsMenuOpen,
  isNotifiOpen,
  setIsNotifiOpen,
}) => {
  const isMobile = useIsMobile();
  const modal = useContext(ModalContext);
  const auth = useContext(AuthContext);
  const notifi = useContext(NotifiContext);
  const history = useHistory();
  const [userMenu, setUserMenu] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.addEventListener("click", () => {
      setIsNotifiOpen(false);
      setUserMenu(false);
    });
  });

  return (
    <>
      <header className="header">
        <a
          href="!#"
          onClick={(e) => {
            e.preventDefault();
            setIsMenuOpen(false);
            history.push("/");
          }}
        >
          <img
            className="header__logo"
            src="/assets/image/logo.png"
            alt="Logo"
          />
        </a>
        <div className="header__actions">
          <button
            className={`btn btn-lang mr-2 ${isMenuOpen ? "active" : ""}`}
            onClick={() => {
              modal.setClasses(["lang"]);
              modal.setChildren(<LangModal />);
              modal.start();
            }}
          >
            <img
              src={`/assets/image/lang/${i18n.resolvedLanguage}.png`}
              alt="Language"
            />
            <i className="icon-arrow-down"></i>
          </button>
          {isMobile ? (
            <button
              className={`btn btn-lang btn-arrow ${!isMenuOpen && !auth.isAuth ? "active" : ""
                }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#405FFF"
                width="20"
                height="20"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </button>
          ) : null}
          {!auth.isAuth && !isMobile && (
            <>
              <button
                className="btn btn-secondary"
                style={{ margin: "0 8px 0 15px" }}
                onClick={() => {
                  modal.setClasses(["login"]);
                  modal.setChildren(<LoginModal />);
                  modal.start();
                }}
              >
                {t("login")}
              </button>
              <button
                className="btn btn-accent"
                onClick={() => {
                  modal.setClasses(["login"]);
                  modal.setChildren(<RegisterModal />);
                  modal.start();
                }}
              >
                {t("registration")}
              </button>
            </>
          )}
          {auth.isAuth && (
            <div className="notify__wrapper">
              <button
                className={
                  isMenuOpen
                    ? "btn header__notify active"
                    : "btn header__notify"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setIsNotifiOpen((prev) => !prev);
                  setUserMenu(false);
                  notifi.readNotifics();
                }}
              >
                <img
                  className="header__avatar-img"
                  src="/assets/image/icon/bell.svg"
                  alt="Bell"
                />
                <span
                  className={
                    notifi.notifics.unread_count && notifi.active
                      ? "active"
                      : ""
                  }
                >
                  {notifi.notifics.unread_count}
                </span>
              </button>
              <div
                className={
                  isNotifiOpen
                    ? `user-menu user-menu__notify active ${notifi.notifics?.list.length ? "" : "empty"
                    }`
                    : `user-menu user-menu__notify ${notifi.notifics?.list.length ? "" : "empty"
                    }`
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="backlight backlight-green one"></div>
                <div className="backlight backlight-blue two"></div>
                <div className="backlight backlight-green three"></div>
                <span
                  className="modal__close"
                  onClick={() => setIsNotifiOpen(false)}
                >
                  &times;
                </span>
                {notifi.notifics?.list?.length ? (
                  <ul className="notify__content">
                    {notifi.notifics.list.map((notific) => {
                      if (notific.type === "BALANCE") {
                        let parts = notific.message.split(":");
                        let [currency, op, amount] = parts;
                        return (
                          <li className={`notify__balance ${op === "DEBIT" ? "up" : "down"}`}>
                            <span>
                              <i className="icon-wallet2"></i>{" "}
                              {op === "DEBIT" ? "Refill" : "Spent"}
                            </span>
                            <span> {op === "DEBIT" ? "+" : "-"} {amount} {currency} </span>
                          </li>
                        )
                      } else if (notific.type === "MESSAGE") {
                        return (
                          <li className={`notify__message`}>
                            <i className="icon-union"></i>
                            <span>{notific.message}</span>
                          </li>
                        )
                      }
                    })}
                  </ul>
                ) : (
                  <>
                    <p>{t("not_notifi")}</p>
                    <img
                      src="/assets/image/pencil-in-hand-2.png"
                      alt="The notific"
                    />
                  </>
                )}
              </div>
            </div>
          )}

          {auth.isAuth && !isMobile && (
            <>
              <button
                className="btn header__avatar"
                onClick={(e) => {
                  e.stopPropagation();
                  setUserMenu((prev) => !prev);
                  setIsNotifiOpen(false);
                }}
              >
                <img
                  className="header__avatar-img"
                  src="/assets/image/avatar.png"
                  alt="Avatar"
                />
              </button>
              <div
                className={
                  !isMobile && userMenu ? "user-menu active" : "user-menu"
                }
              >
                <div
                  className="user-menu__item"
                  onClick={() => {
                    history.push("/setting");
                  }}
                >
                  <i className="icon-settings"></i>
                  {t("menu.settings")}
                </div>
                <div
                  className="user-menu__item"
                  onClick={() => {
                    auth.logout();
                  }}
                >
                  <i className="icon-logout"></i>
                  {t("menu.logout")}
                </div>
              </div>
            </>
          )}
          {isMobile && (
            <button
              className="btn btn-menu"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            ></button>
          )}
        </div>
      </header>
    </>
  );
};
