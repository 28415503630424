import { useCallback, useState } from "react";

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(
    async (url, method = "GET", token = null, body = null, headers = {}) => {
      setLoading(true);

      try {
        if (body && !(body instanceof FormData)) {
          body = JSON.stringify(body);
          headers["Content-Type"] = "application/json";
        }

        if (token) {
          headers["Authorization"] = `Bearer ${token}`;
        }

        const response = await fetch(process.env.REACT_APP_API_HOST + url, {
          method,
          body,
          headers,
        });
        const data = await response.json().catch(() => {});

        if (!response.ok) {
          throw new Error(data.message || "server_error");
        }

        setLoading(false);

        return data;
      } catch (e) {
        setLoading(false);
        setError(e.message);
        throw e;
      }
    },
    []
  );

  const clearError = useCallback(() => setError(null), []);

  return { loading, error, request, clearError };
};
