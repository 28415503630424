import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useHttp } from "../hooks/http.hook";

export const QueueMatchForm = ({ trigger }) => {
  const [matches, setMatches] = useState([]);
  const { request, loading } = useHttp();
  const { token } = useContext(AuthContext);
  const history = useHistory();

  const getMatches = useCallback(async () => {
    try {
      const response = await request("/match/queue", "GET", token);
      setMatches(response);
    } catch (e) {}
  }, [request, token]);

  useEffect(() => getMatches(), [getMatches, trigger]);

  const formatNumber = (n) => (n > 9 ? n : "0" + n);

  const getFromatDate = (date, time) => {
    const d = new Date(`${date} ${time}`);

    const day = formatNumber(d.getDate());
    const month = formatNumber(d.getMonth() + 1);
    const hours = formatNumber(d.getHours());
    const minutes = formatNumber(d.getMinutes());

    return `${day}.${month} ${hours}:${minutes}`;
  };

  const deleteMatch = async (id) => {
    try {
      await request(`/match/${id}`, "DELETE", token);
      setMatches((prev) => prev.filter((match) => match.id !== id));
    } catch (e) {}
  };

  const updateResult = async (id, result) => {
    try {
      await request(`/match/${id}`, "PUT", token, { result: !!result });
      setMatches((prev) => prev.filter((match) => match.id !== id));
    } catch (e) {}
  };

  return (
    <section className="overflow-x-auto">
      <table>
        <thead>
          <tr>
            <td className="date">Date</td>
            <td className="country">Country</td>
            <td className="teams">Teams</td>
            <td className="info">Info</td>
            <td className="odds">Odds</td>
            <td className="result">Win/Lose</td>
            <td className="result">Edit</td>
            <td className="result">Delete</td>
          </tr>
        </thead>
        <tbody>
          {matches.map((match, i) => (
            <tr>
              <td className="date">{getFromatDate(match.date, match.time)}</td>
              <td className="country">
                <img
                  className="lang-icon"
                  alt={match.country.iso}
                  title={match.country.iso}
                  src={
                    process.env.REACT_APP_API_HOST +
                    "/flags/" +
                    match.country.iso +
                    ".png"
                  }
                />
              </td>
              <td className="teams">{match.teams}</td>
              <td className="info">{match.info}</td>
              <td className="odds">{match.odds}</td>
              <td className="result">
                {i === 0 && (
                  <select
                    onChange={(e) =>
                      e.target.value &&
                      updateResult(match.id, Number(e.target.value))
                    }
                    disabled={loading}
                  >
                    <option value="">...</option>
                    <option value="1">Win</option>
                    <option value="0">Lose</option>
                  </select>
                )}
              </td>
              <td className="result">
                <button
                  className="btn btn-edit"
                  disabled={loading}
                  onClick={() => {
                    history.push(`/admin/match/${match.id}`);
                  }}
                >
                  Edit
                </button>
              </td>
              <td className="result">
                <button
                  className="btn btn-delete"
                  disabled={loading}
                  onClick={() => {
                    deleteMatch(match.id);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};
